import states from "states-us";
// import csc from "countries-states-cities"; // https://www.npmjs.com/package/countries-states-cities
// import worldMapData from "city-state-country";
export default function optionStateCodesUnitedState() {
	let arr1 = [];
	states.map((row, key) => {
		if (
			row.name !== "Federated States Of Micronesia" &&
			row.name !== "Marshall Islands" &&
			row.name !== "Palau" &&
			row.name !== "Northern Mariana Islands"
		) {
			arr1.push({
				value: row.abbreviation,
				label: row.name + " (" + row.abbreviation + ")",
				// json: worldMapData.getAllCitiesFromState(row.name),
			});
		}
		return "";
	});

	// let state = csc.getStatesOfCountry(233);
	// state.map((row, key) => {
	//   if (
	//     row.name !== "Federated States Of Micronesia" &&
	//     row.name !== "Marshall Islands" &&
	//     row.name !== "Palau" &&
	//     row.name !== "Northern Mariana Islands"
	//   ) {
	//     arr.push({
	//       value: row.state_code,
	//       label: row.name + " (" + row.state_code + ")",
	//       json: row,
	//     });
	//   }
	//   return "";
	// });

	let arr = [
		{
			label: "Alabama (AL)",
			value: "AL",
			json: [
				{
					label: "Alexander City",
					value: "Alexander City",
				},
				{
					label: "Andalusia",
					value: "Andalusia",
				},
				{
					label: "Anniston",
					value: "Anniston",
				},
				{
					label: "Athens",
					value: "Athens",
				},
				{
					label: "Atmore",
					value: "Atmore",
				},
				{
					label: "Auburn",
					value: "Auburn",
				},
				{
					label: "Bessemer",
					value: "Bessemer",
				},
				{
					label: "Birmingham",
					value: "Birmingham",
				},
				{
					label: "Chickasaw",
					value: "Chickasaw",
				},
				{
					label: "Clanton",
					value: "Clanton",
				},
				{
					label: "Cullman",
					value: "Cullman",
				},
				{
					label: "Decatur",
					value: "Decatur",
				},
				{
					label: "Demopolis",
					value: "Demopolis",
				},
				{
					label: "Dothan",
					value: "Dothan",
				},
				{
					label: "Enterprise",
					value: "Enterprise",
				},
				{
					label: "Eufaula",
					value: "Eufaula",
				},
				{
					label: "Florence",
					value: "Florence",
				},
				{
					label: "Fort Payne",
					value: "Fort Payne",
				},
				{
					label: "Gadsden",
					value: "Gadsden",
				},
				{
					label: "Greenville",
					value: "Greenville",
				},
				{
					label: "Guntersville",
					value: "Guntersville",
				},
				{
					label: "Huntsville",
					value: "Huntsville",
				},
				{
					label: "Jasper",
					value: "Jasper",
				},
				{
					label: "Marion",
					value: "Marion",
				},
				{
					label: "Mobile",
					value: "Mobile",
				},
				{
					label: "Montgomery",
					value: "Montgomery",
				},
				{
					label: "Opelika",
					value: "Opelika",
				},
				{
					label: "Ozark",
					value: "Ozark",
				},
				{
					label: "Phenix City",
					value: "Phenix City",
				},
				{
					label: "Prichard",
					value: "Prichard",
				},
				{
					label: "Scottsboro",
					value: "Scottsboro",
				},
				{
					label: "Selma",
					value: "Selma",
				},
				{
					label: "Sheffield",
					value: "Sheffield",
				},
				{
					label: "Sylacauga",
					value: "Sylacauga",
				},
				{
					label: "Talladega",
					value: "Talladega",
				},
				{
					label: "Troy",
					value: "Troy",
				},
				{
					label: "Tuscaloosa",
					value: "Tuscaloosa",
				},
				{
					label: "Tuscumbia",
					value: "Tuscumbia",
				},
				{
					label: "Tuskegee",
					value: "Tuskegee",
				},
			],
		},
		{
			value: "AK",
			label: "Alaska (AK)",
			json: [
				{ label: "Anchorage", value: "Anchorage" },
				{ label: "Cordova", value: "Cordova" },
				{ label: "Fairbanks", value: "Fairbanks" },
				{ label: "Haines", value: "Haines" },
				{ label: "Homer", value: "Homer" },
				{ label: "Juneau", value: "Juneau" },
				{ label: "Ketchikan", value: "Ketchikan" },
				{ label: "Kodiak", value: "Kodiak" },
				{ label: "Kotzebue", value: "Kotzebue" },
				{ label: "Nome", value: "Nome" },
				{ label: "Palmer", value: "Palmer" },
				{ label: "Seward", value: "Seward" },
				{ label: "Sitka", value: "Sitka" },
				{ label: "Skagway", value: "Skagway" },
				{ label: "Valdez", value: "Valdez" },
			],
		},
		{
			value: "AS",
			label: "American Samoa (AS)",
			json: [
				{ label: "Tula", value: "Tula" },
				{ label: "Utulei", value: "Utulei" },
				{ label: "Vailoatai", value: "Vailoatai" },
				{ label: "Vaitogi", value: "Vaitogi" },
				{ label: "Fitiuta", value: "Fitiuta" },
				{ label: "Vaoto", value: "Vaoto" },
				{ label: "Luma", value: "Luma" },
			],
		},
		{
			value: "AZ",
			label: "Arizona (AZ)",
			json: [
				{ label: "Ajo", value: "Ajo" },
				{ label: "Avondale", value: "Avondale" },
				{ label: "Bisbee", value: "Bisbee" },
				{ label: "Casa Grande", value: "Casa Grande" },
				{ label: "Chandler", value: "Chandler" },
				{ label: "Clifton", value: "Clifton" },
				{ label: "Douglas", value: "Douglas" },
				{ label: "Flagstaff", value: "Flagstaff" },
				{ label: "Florence", value: "Florence" },
				{ label: "Gila Bend", value: "Gila Bend" },
				{ label: "Glendale", value: "Glendale" },
				{ label: "Globe", value: "Globe" },
				{ label: "Kingman", value: "Kingman" },
				{ label: "Lake Havasu City", value: "Lake Havasu City" },
				{ label: "Mesa", value: "Mesa" },
				{ label: "Nogales", value: "Nogales" },
				{ label: "Oraibi", value: "Oraibi" },
				{ label: "Phoenix", value: "Phoenix" },
				{ label: "Prescott", value: "Prescott" },
				{ label: "Scottsdale", value: "Scottsdale" },
				{ label: "Sierra Vista", value: "Sierra Vista" },
				{ label: "Tempe", value: "Tempe" },
				{ label: "Tombstone", value: "Tombstone" },
				{ label: "Tucson", value: "Tucson" },
				{ label: "Walpi", value: "Walpi" },
				{ label: "Window Rock", value: "Window Rock" },
				{ label: "Winslow", value: "Winslow" },
				{ label: "Yuma", value: "Yuma" },
			],
		},
		{
			value: "AR",
			label: "Arkansas (AR)",
			json: [
				{ label: "Arkadelphia", value: "Arkadelphia" },
				{ label: "Arkansas Post", value: "Arkansas Post" },
				{ label: "Batesville", value: "Batesville" },
				{ label: "Benton", value: "Benton" },
				{ label: "Blytheville", value: "Blytheville" },
				{ label: "Camden", value: "Camden" },
				{ label: "Conway", value: "Conway" },
				{ label: "Crossett", value: "Crossett" },
				{ label: "El Dorado", value: "El Dorado" },
				{ label: "Fayetteville", value: "Fayetteville" },
				{ label: "Forrest City", value: "Forrest City" },
				{ label: "Fort Smith", value: "Fort Smith" },
				{ label: "Harrison", value: "Harrison" },
				{ label: "Helena", value: "Helena" },
				{ label: "Hope", value: "Hope" },
				{ label: "Hot Springs", value: "Hot Springs" },
				{ label: "Jacksonville", value: "Jacksonville" },
				{ label: "Jonesboro", value: "Jonesboro" },
				{ label: "Little Rock", value: "Little Rock" },
				{ label: "Magnolia", value: "Magnolia" },
				{ label: "Morrilton", value: "Morrilton" },
				{ label: "Newport", value: "Newport" },
				{ label: "North Little Rock", value: "North Little Rock" },
				{ label: "Osceola", value: "Osceola" },
				{ label: "Pine Bluff", value: "Pine Bluff" },
				{ label: "Rogers", value: "Rogers" },
				{ label: "Searcy", value: "Searcy" },
				{ label: "Stuttgart", value: "Stuttgart" },
				{ label: "Van Buren", value: "Van Buren" },
				{ label: "West Memphis", value: "West Memphis" },
			],
		},
		{
			value: "CA",
			label: "California (CA)",
			json: [
				{ label: "Alameda", value: "Alameda" },
				{ label: "Alhambra", value: "Alhambra" },
				{ label: "Anaheim", value: "Anaheim" },
				{ label: "Antioch", value: "Antioch" },
				{ label: "Arcadia", value: "Arcadia" },
				{ label: "Bakersfield", value: "Bakersfield" },
				{ label: "Barstow", value: "Barstow" },
				{ label: "Belmont", value: "Belmont" },
				{ label: "Berkeley", value: "Berkeley" },
				{ label: "Beverly Hills", value: "Beverly Hills" },
				{ label: "Brea", value: "Brea" },
				{ label: "Buena Park", value: "Buena Park" },
				{ label: "Burbank", value: "Burbank" },
				{ label: "Calexico", value: "Calexico" },
				{ label: "Calistoga", value: "Calistoga" },
				{ label: "Carlsbad", value: "Carlsbad" },
				{ label: "Carmel", value: "Carmel" },
				{ label: "Chico", value: "Chico" },
				{ label: "Chula Vista", value: "Chula Vista" },
				{ label: "Claremont", value: "Claremont" },
				{ label: "Compton", value: "Compton" },
				{ label: "Concord", value: "Concord" },
				{ label: "Corona", value: "Corona" },
				{ label: "Coronado", value: "Coronado" },
				{ label: "Costa Mesa", value: "Costa Mesa" },
				{ label: "Culver City", value: "Culver City" },
				{ label: "Daly City", value: "Daly City" },
				{ label: "Davis", value: "Davis" },
				{ label: "Downey", value: "Downey" },
				{ label: "El Centro", value: "El Centro" },
				{ label: "El Cerrito", value: "El Cerrito" },
				{ label: "El Monte", value: "El Monte" },
				{ label: "Escondido", value: "Escondido" },
				{ label: "Eureka", value: "Eureka" },
				{ label: "Fairfield", value: "Fairfield" },
				{ label: "Fontana", value: "Fontana" },
				{ label: "Fremont", value: "Fremont" },
				{ label: "Fresno", value: "Fresno" },
				{ label: "Fullerton", value: "Fullerton" },
				{ label: "Garden Grove", value: "Garden Grove" },
				{ label: "Glendale", value: "Glendale" },
				{ label: "Hayward", value: "Hayward" },
				{ label: "Hollywood", value: "Hollywood" },
				{ label: "Huntington Beach", value: "Huntington Beach" },
				{ label: "Indio", value: "Indio" },
				{ label: "Inglewood", value: "Inglewood" },
				{ label: "Irvine", value: "Irvine" },
				{ label: "La Habra", value: "La Habra" },
				{ label: "Laguna Beach", value: "Laguna Beach" },
				{ label: "Lancaster", value: "Lancaster" },
				{ label: "Livermore", value: "Livermore" },
				{ label: "Lodi", value: "Lodi" },
				{ label: "Lompoc", value: "Lompoc" },
				{ label: "Long Beach", value: "Long Beach" },
				{ label: "Los Angeles", value: "Los Angeles" },
				{ label: "Malibu", value: "Malibu" },
				{ label: "Martinez", value: "Martinez" },
				{ label: "Marysville", value: "Marysville" },
				{ label: "Menlo Park", value: "Menlo Park" },
				{ label: "Merced", value: "Merced" },
				{ label: "Modesto", value: "Modesto" },
				{ label: "Monterey", value: "Monterey" },
				{ label: "Mountain View", value: "Mountain View" },
				{ label: "Napa", value: "Napa" },
				{ label: "Needles", value: "Needles" },
				{ label: "Newport Beach", value: "Newport Beach" },
				{ label: "Norwalk", value: "Norwalk" },
				{ label: "Novato", value: "Novato" },
				{ label: "Oakland", value: "Oakland" },
				{ label: "Oceanside", value: "Oceanside" },
				{ label: "Ojai", value: "Ojai" },
				{ label: "Ontario", value: "Ontario" },
				{ label: "Orange", value: "Orange" },
				{ label: "Oroville", value: "Oroville" },
				{ label: "Oxnard", value: "Oxnard" },
				{ label: "Pacific Grove", value: "Pacific Grove" },
				{ label: "Palm Springs", value: "Palm Springs" },
				{ label: "Palmdale", value: "Palmdale" },
				{ label: "Palo Alto", value: "Palo Alto" },
				{ label: "Pasadena", value: "Pasadena" },
				{ label: "Petaluma", value: "Petaluma" },
				{ label: "Pomona", value: "Pomona" },
				{ label: "Port Hueneme", value: "Port Hueneme" },
				{ label: "Rancho Cucamonga", value: "Rancho Cucamonga" },
				{ label: "Red Bluff", value: "Red Bluff" },
				{ label: "Redding", value: "Redding" },
				{ label: "Redlands", value: "Redlands" },
				{ label: "Redondo Beach", value: "Redondo Beach" },
				{ label: "Redwood City", value: "Redwood City" },
				{ label: "Richmond", value: "Richmond" },
				{ label: "Riverside", value: "Riverside" },
				{ label: "Roseville", value: "Roseville" },
				{ label: "Sacramento", value: "Sacramento" },
				{ label: "Salinas", value: "Salinas" },
				{ label: "San Bernardino", value: "San Bernardino" },
				{ label: "San Clemente", value: "San Clemente" },
				{ label: "San Diego", value: "San Diego" },
				{ label: "San Fernando", value: "San Fernando" },
				{ label: "San Francisco", value: "San Francisco" },
				{ label: "San Gabriel", value: "San Gabriel" },
				{ label: "San Jose", value: "San Jose" },
				{ label: "San Juan Capistrano", value: "San Juan Capistrano" },
				{ label: "San Leandro", value: "San Leandro" },
				{ label: "San Luis Obispo", value: "San Luis Obispo" },
				{ label: "San Marino", value: "San Marino" },
				{ label: "San Mateo", value: "San Mateo" },
				{ label: "San Pedro", value: "San Pedro" },
				{ label: "San Rafael", value: "San Rafael" },
				{ label: "San Simeon", value: "San Simeon" },
				{ label: "Santa Ana", value: "Santa Ana" },
				{ label: "Santa Barbara", value: "Santa Barbara" },
				{ label: "Santa Clara", value: "Santa Clara" },
				{ label: "Santa Clarita", value: "Santa Clarita" },
				{ label: "Santa Cruz", value: "Santa Cruz" },
				{ label: "Santa Monica", value: "Santa Monica" },
				{ label: "Santa Rosa", value: "Santa Rosa" },
				{ label: "Sausalito", value: "Sausalito" },
				{ label: "Simi Valley", value: "Simi Valley" },
				{ label: "Sonoma", value: "Sonoma" },
				{ label: "South San Francisco", value: "South San Francisco" },
				{ label: "Stockton", value: "Stockton" },
				{ label: "Sunnyvale", value: "Sunnyvale" },
				{ label: "Susanville", value: "Susanville" },
				{ label: "Thousand Oaks", value: "Thousand Oaks" },
				{ label: "Torrance", value: "Torrance" },
				{ label: "Turlock", value: "Turlock" },
				{ label: "Ukiah", value: "Ukiah" },
				{ label: "Vallejo", value: "Vallejo" },
				{ label: "Ventura", value: "Ventura" },
				{ label: "Victorville", value: "Victorville" },
				{ label: "Visalia", value: "Visalia" },
				{ label: "Walnut Creek", value: "Walnut Creek" },
				{ label: "Watts", value: "Watts" },
				{ label: "West Covina", value: "West Covina" },
				{ label: "Whittier", value: "Whittier" },
				{ label: "Woodland", value: "Woodland" },
				{ label: "Yorba Linda", value: "Yorba Linda" },
				{ label: "Yuba City", value: "Yuba City" },
			],
		},
		{
			value: "CO",
			label: "Colorado (CO)",
			json: [
				{ label: "Alamosa", value: "Alamosa" },
				{ label: "Aspen", value: "Aspen" },
				{ label: "Aurora", value: "Aurora" },
				{ label: "Boulder", value: "Boulder" },
				{ label: "Breckenridge", value: "Breckenridge" },
				{ label: "Brighton", value: "Brighton" },
				{ label: "Canon City", value: "Canon City" },
				{ label: "Central City", value: "Central City" },
				{ label: "Climax", value: "Climax" },
				{ label: "Colorado Springs", value: "Colorado Springs" },
				{ label: "Cortez", value: "Cortez" },
				{ label: "Cripple Creek", value: "Cripple Creek" },
				{ label: "Denver", value: "Denver" },
				{ label: "Durango", value: "Durango" },
				{ label: "Englewood", value: "Englewood" },
				{ label: "Estes Park", value: "Estes Park" },
				{ label: "Fort Collins", value: "Fort Collins" },
				{ label: "Fort Morgan", value: "Fort Morgan" },
				{ label: "Georgetown", value: "Georgetown" },
				{ label: "Glenwood Springs", value: "Glenwood Springs" },
				{ label: "Golden", value: "Golden" },
				{ label: "Grand Junction", value: "Grand Junction" },
				{ label: "Greeley", value: "Greeley" },
				{ label: "Gunnison", value: "Gunnison" },
				{ label: "La Junta", value: "La Junta" },
				{ label: "Leadville", value: "Leadville" },
				{ label: "Littleton", value: "Littleton" },
				{ label: "Longmont", value: "Longmont" },
				{ label: "Loveland", value: "Loveland" },
				{ label: "Montrose", value: "Montrose" },
				{ label: "Ouray", value: "Ouray" },
				{ label: "Pagosa Springs", value: "Pagosa Springs" },
				{ label: "Pueblo", value: "Pueblo" },
				{ label: "Silverton", value: "Silverton" },
				{ label: "Steamboat Springs", value: "Steamboat Springs" },
				{ label: "Sterling", value: "Sterling" },
				{ label: "Telluride", value: "Telluride" },
				{ label: "Trinidad", value: "Trinidad" },
				{ label: "Vail", value: "Vail" },
				{ label: "Walsenburg", value: "Walsenburg" },
				{ label: "Westminster", value: "Westminster" },
			],
		},
		{
			value: "CT",
			label: "Connecticut (CT)",
			json: [
				{ label: "Ansonia", value: "Ansonia" },
				{ label: "Berlin", value: "Berlin" },
				{ label: "Bloomfield", value: "Bloomfield" },
				{ label: "Branford", value: "Branford" },
				{ label: "Bridgeport", value: "Bridgeport" },
				{ label: "Bristol", value: "Bristol" },
				{ label: "Coventry", value: "Coventry" },
				{ label: "Danbury", value: "Danbury" },
				{ label: "Darien", value: "Darien" },
				{ label: "Derby", value: "Derby" },
				{ label: "East Hartford", value: "East Hartford" },
				{ label: "East Haven", value: "East Haven" },
				{ label: "Enfield", value: "Enfield" },
				{ label: "Fairfield", value: "Fairfield" },
				{ label: "Farmington", value: "Farmington" },
				{ label: "Greenwich", value: "Greenwich" },
				{ label: "Groton", value: "Groton" },
				{ label: "Guilford", value: "Guilford" },
				{ label: "Hamden", value: "Hamden" },
				{ label: "Hartford", value: "Hartford" },
				{ label: "Lebanon", value: "Lebanon" },
				{ label: "Litchfield", value: "Litchfield" },
				{ label: "Manchester", value: "Manchester" },
				{ label: "Mansfield", value: "Mansfield" },
				{ label: "Meriden", value: "Meriden" },
				{ label: "Middletown", value: "Middletown" },
				{ label: "Milford", value: "Milford" },
				{ label: "Mystic", value: "Mystic" },
				{ label: "Naugatuck", value: "Naugatuck" },
				{ label: "New Britain", value: "New Britain" },
				{ label: "New Haven", value: "New Haven" },
				{ label: "New London", value: "New London" },
				{ label: "North Haven", value: "North Haven" },
				{ label: "Norwalk", value: "Norwalk" },
				{ label: "Norwich", value: "Norwich" },
				{ label: "Old Saybrook", value: "Old Saybrook" },
				{ label: "Orange", value: "Orange" },
				{ label: "Seymour", value: "Seymour" },
				{ label: "Shelton", value: "Shelton" },
				{ label: "Simsbury", value: "Simsbury" },
				{ label: "Southington", value: "Southington" },
				{ label: "Stamford", value: "Stamford" },
				{ label: "Stonington", value: "Stonington" },
				{ label: "Stratford", value: "Stratford" },
				{ label: "Torrington", value: "Torrington" },
				{ label: "Wallingford", value: "Wallingford" },
				{ label: "Waterbury", value: "Waterbury" },
				{ label: "Waterford", value: "Waterford" },
				{ label: "Watertown", value: "Watertown" },
				{ label: "West Hartford", value: "West Hartford" },
				{ label: "West Haven", value: "West Haven" },
				{ label: "Westport", value: "Westport" },
				{ label: "Wethersfield", value: "Wethersfield" },
				{ label: "Willimantic", value: "Willimantic" },
				{ label: "Windham", value: "Windham" },
				{ label: "Windsor", value: "Windsor" },
				{ label: "Windsor Locks", value: "Windsor Locks" },
				{ label: "Winsted", value: "Winsted" },
			],
		},
		{
			value: "DE",
			label: "Delaware (DE)",
			json: [
				{ label: "Dover", value: "Dover" },
				{ label: "Lewes", value: "Lewes" },
				{ label: "Milford", value: "Milford" },
				{ label: "New Castle", value: "New Castle" },
				{ label: "Newark", value: "Newark" },
				{ label: "Smyrna", value: "Smyrna" },
				{ label: "Wilmington", value: "Wilmington" },
			],
		},
		// { value: "DC", label: "District Of Columbia (DC)" },
		{
			value: "FL",
			label: "Florida (FL)",
			json: [
				{ label: "Apalachicola", value: "Apalachicola" },
				{ label: "Bartow", value: "Bartow" },
				{ label: "Belle Glade", value: "Belle Glade" },
				{ label: "Boca Raton", value: "Boca Raton" },
				{ label: "Bradenton", value: "Bradenton" },
				{ label: "Cape Coral", value: "Cape Coral" },
				{ label: "Clearwater", value: "Clearwater" },
				{ label: "Cocoa Beach", value: "Cocoa Beach" },
				{ label: "Cocoa-Rockledge", value: "Cocoa-Rockledge" },
				{ label: "Coral Gables", value: "Coral Gables" },
				{ label: "Daytona Beach", value: "Daytona Beach" },
				{ label: "De Land", value: "De Land" },
				{ label: "Deerfield Beach", value: "Deerfield Beach" },
				{ label: "Delray Beach", value: "Delray Beach" },
				{ label: "Fernandina Beach", value: "Fernandina Beach" },
				{ label: "Fort Lauderdale", value: "Fort Lauderdale" },
				{ label: "Fort Myers", value: "Fort Myers" },
				{ label: "Fort Pierce", value: "Fort Pierce" },
				{ label: "Fort Walton Beach", value: "Fort Walton Beach" },
				{ label: "Gainesville", value: "Gainesville" },
				{ label: "Hallandale Beach", value: "Hallandale Beach" },
				{ label: "Hialeah", value: "Hialeah" },
				{ label: "Hollywood", value: "Hollywood" },
				{ label: "Homestead", value: "Homestead" },
				{ label: "Jacksonville", value: "Jacksonville" },
				{ label: "Key West", value: "Key West" },
				{ label: "Lake City", value: "Lake City" },
				{ label: "Lake Wales", value: "Lake Wales" },
				{ label: "Lakeland", value: "Lakeland" },
				{ label: "Largo", value: "Largo" },
				{ label: "Melbourne", value: "Melbourne" },
				{ label: "Miami", value: "Miami" },
				{ label: "Miami Beach", value: "Miami Beach" },
				{ label: "Naples", value: "Naples" },
				{ label: "New Smyrna Beach", value: "New Smyrna Beach" },
				{ label: "Ocala", value: "Ocala" },
				{ label: "Orlando", value: "Orlando" },
				{ label: "Ormond Beach", value: "Ormond Beach" },
				{ label: "Palatka", value: "Palatka" },
				{ label: "Palm Bay", value: "Palm Bay" },
				{ label: "Palm Beach", value: "Palm Beach" },
				{ label: "Panama City", value: "Panama City" },
				{ label: "Pensacola", value: "Pensacola" },
				{ label: "Pompano Beach", value: "Pompano Beach" },
				{ label: "Saint Augustine", value: "Saint Augustine" },
				{ label: "Saint Petersburg", value: "Saint Petersburg" },
				{ label: "Sanford", value: "Sanford" },
				{ label: "Sarasota", value: "Sarasota" },
				{ label: "Sebring", value: "Sebring" },
				{ label: "Tallahassee", value: "Tallahassee" },
				{ label: "Tampa", value: "Tampa" },
				{ label: "Tarpon Springs", value: "Tarpon Springs" },
				{ label: "Titusville", value: "Titusville" },
				{ label: "Venice", value: "Venice" },
				{ label: "West Palm Beach", value: "West Palm Beach" },
				{ label: "White Springs", value: "White Springs" },
				{ label: "Winter Haven", value: "Winter Haven" },
				{ label: "Winter Park", value: "Winter Park" },
			],
		},
		{
			value: "GA",
			label: "Georgia (GA)",
			json: [
				{ label: "Albany", value: "Albany" },
				{ label: "Americus", value: "Americus" },
				{ label: "Andersonville", value: "Andersonville" },
				{ label: "Athens", value: "Athens" },
				{ label: "Atlanta", value: "Atlanta" },
				{ label: "Augusta", value: "Augusta" },
				{ label: "Bainbridge", value: "Bainbridge" },
				{ label: "Blairsville", value: "Blairsville" },
				{ label: "Brunswick", value: "Brunswick" },
				{ label: "Calhoun", value: "Calhoun" },
				{ label: "Carrollton", value: "Carrollton" },
				{ label: "Columbus", value: "Columbus" },
				{ label: "Dahlonega", value: "Dahlonega" },
				{ label: "Dalton", value: "Dalton" },
				{ label: "Darien", value: "Darien" },
				{ label: "Decatur", value: "Decatur" },
				{ label: "Douglas", value: "Douglas" },
				{ label: "East Point", value: "East Point" },
				{ label: "Fitzgerald", value: "Fitzgerald" },
				{ label: "Fort Valley", value: "Fort Valley" },
				{ label: "Gainesville", value: "Gainesville" },
				{ label: "La Grange", value: "La Grange" },
				{ label: "Macon", value: "Macon" },
				{ label: "Marietta", value: "Marietta" },
				{ label: "Milledgeville", value: "Milledgeville" },
				{ label: "Plains", value: "Plains" },
				{ label: "Rome", value: "Rome" },
				{ label: "Savannah", value: "Savannah" },
				{ label: "Toccoa", value: "Toccoa" },
				{ label: "Valdosta", value: "Valdosta" },
				{ label: "Warm Springs", value: "Warm Springs" },
				{ label: "Warner Robins", value: "Warner Robins" },
				{ label: "Washington", value: "Washington" },
				{ label: "Waycross", value: "Waycross" },
			],
		},
		{
			value: "GU",
			label: "Guam (GU)",
			json: [
				{ label: "Adacao", value: "Adacao" },
				{ label: "Adelup", value: "Adelup" },
				{ label: "Afame", value: "Afame" },
				{ label: "Afami", value: "Afami" },
				{ label: "Agafo Gumas", value: "Agafo Gumas" },
				{ label: "Agat", value: "Agat" },
				{ label: "Anigua", value: "Anigua" },
				{ label: "Apoigan", value: "Apoigan" },
				{ label: "Apra Heights", value: "Apra Heights" },
				{ label: "Apra Junction", value: "Apra Junction" },
				{ label: "Apurguan", value: "Apurguan" },
				{ label: "Asagas", value: "Asagas" },
				{ label: "Asan", value: "Asan" },
				{ label: "Asatdas", value: "Asatdas" },
				{ label: "Asbeco", value: "Asbeco" },
				{ label: "Asfaja", value: "Asfaja" },
				{ label: "Asmisen", value: "Asmisen" },
				{ label: "Asmuyao", value: "Asmuyao" },
				{ label: "Aspengo", value: "Aspengo" },
				{ label: "Astumbo", value: "Astumbo" },
				{ label: "Ayuja", value: "Ayuja" },
				{ label: "Bacuna", value: "Bacuna" },
				{ label: "Barrigada", value: "Barrigada" },
				{ label: "Barrigada Heights", value: "Barrigada Heights" },
				{ label: "Baza Gardens", value: "Baza Gardens" },
				{ label: "Bile", value: "Bile" },
				{ label: "Bingot", value: "Bingot" },
				{ label: "Biyae", value: "Biyae" },
				{ label: "Bordallo Subdivision", value: "Bordallo Subdivision" },
				{ label: "Bordallo Subdivision", value: "Bordallo Subdivision" },
				{ label: "Calacag", value: "Calacag" },
				{ label: "Camp Dealy", value: "Camp Dealy" },
				{ label: "Camp Roxas", value: "Camp Roxas" },
				{ label: "Canada", value: "Canada" },
				{ label: "Carlos Heights", value: "Carlos Heights" },
				{ label: "Catan Maga", value: "Catan Maga" },
				{ label: "Cerain", value: "Cerain" },
				{ label: "Chagamin Lago", value: "Chagamin Lago" },
				{ label: "Chalan Mamajanao", value: "Chalan Mamajanao" },
				{ label: "Chalan Pago", value: "Chalan Pago" },
				{ label: "Coontz Junction", value: "Coontz Junction" },
				{ label: "Corten Torres", value: "Corten Torres" },
				{ label: "Dededo", value: "Dededo" },
				{ label: "Dededo Village", value: "Dededo Village" },
				{ label: "Dedidu", value: "Dedidu" },
				{ label: "East Hagåtña", value: "East Hagåtña" },
				{ label: "Flora Pago Gardens", value: "Flora Pago Gardens" },
				{ label: "Gayinero", value: "Gayinero" },
				{
					label: "Hafa Adai Village Subdivision",
					value: "Hafa Adai Village Subdivision",
				},
				{ label: "Hagåtña Heights", value: "Hagåtña Heights" },
				{ label: "Haputo (historical)", value: "Haputo (historical)" },
				{ label: "Harmon Garden", value: "Harmon Garden" },
				{ label: "Harmon Park Subdivision", value: "Harmon Park Subdivision" },
				{ label: "Harmon Village", value: "Harmon Village" },
				{ label: "Hilaan", value: "Hilaan" },
				{ label: "Hyundai", value: "Hyundai" },
				{ label: "Inarajan", value: "Inarajan" },
				{ label: "Ipapao", value: "Ipapao" },
				{ label: "Jaotan", value: "Jaotan" },
				{ label: "Jonestown", value: "Jonestown" },
				{ label: "Julog", value: "Julog" },
				{ label: "Laguas (historical)", value: "Laguas (historical)" },
				{ label: "Lalo", value: "Lalo" },
				{ label: "Las Palmas Subdivision", value: "Las Palmas Subdivision" },
				{ label: "Latte Heights Estates", value: "Latte Heights Estates" },
				{ label: "Latte Plantation", value: "Latte Plantation" },
				{ label: "Leary Junction", value: "Leary Junction" },
				{ label: "Leyan", value: "Leyan" },
				{ label: "Libugon", value: "Libugon" },
				{ label: "Liguan Terrace", value: "Liguan Terrace" },
				{ label: "Lingae", value: "Lingae" },
				{ label: "Lockwood Terrace", value: "Lockwood Terrace" },
				{ label: "Lupog", value: "Lupog" },
				{ label: "Machaute", value: "Machaute" },
				{
					label: "Macheche Village Subdivision",
					value: "Macheche Village Subdivision",
				},
				{ label: "Magua", value: "Magua" },
				{ label: "Maina", value: "Maina" },
				{ label: "Maite", value: "Maite" },
				{ label: "Manaca", value: "Manaca" },
				{ label: "Mangilao", value: "Mangilao" },
				{ label: "Marianas Terrace", value: "Marianas Terrace" },
				{ label: "Masalog", value: "Masalog" },
				{ label: "Matugan", value: "Matugan" },
				{ label: "Mayaya", value: "Mayaya" },
				{ label: "Mepo", value: "Mepo" },
				{ label: "Merizo", value: "Merizo" },
				{ label: "Mongmong", value: "Mongmong" },
				{ label: "Nisshio Subdivision", value: "Nisshio Subdivision" },
				{ label: "North Tipalao", value: "North Tipalao" },
				{ label: "Oceanview", value: "Oceanview" },
				{ label: "Oka", value: "Oka" },
				{ label: "Ordot", value: "Ordot" },
				{ label: "Pado", value: "Pado" },
				{ label: "Pagachao Subdivision", value: "Pagachao Subdivision" },
				{ label: "Pagat", value: "Pagat" },
				{ label: "Paseo De Oro", value: "Paseo De Oro" },
				{ label: "Peca", value: "Peca" },
				{ label: "Perez Acres", value: "Perez Acres" },
				{ label: "Piga", value: "Piga" },
				{ label: "Pigo", value: "Pigo" },
				{ label: "Pigua", value: "Pigua" },
				{ label: "Piti", value: "Piti" },
				{ label: "Ponderosa Gardens", value: "Ponderosa Gardens" },
				{ label: "Potts Junction", value: "Potts Junction" },
				{ label: "Presidio", value: "Presidio" },
				{ label: "Pugua", value: "Pugua" },
				{ label: "Royal Garden", value: "Royal Garden" },
				{ label: "Salas Subdivision", value: "Salas Subdivision" },
				{ label: "Salinas", value: "Salinas" },
				{ label: "Salisbury Junction", value: "Salisbury Junction" },
				{ label: "San Antonio", value: "San Antonio" },
				{ label: "San Ramon", value: "San Ramon" },
				{ label: "San Vicente", value: "San Vicente" },
				{ label: "Santa Rita", value: "Santa Rita" },
				{ label: "Santa Rita Village", value: "Santa Rita Village" },
				{ label: "Santa Rosa", value: "Santa Rosa" },
				{ label: "Schroeder Junction", value: "Schroeder Junction" },
				{ label: "Sella", value: "Sella" },
				{ label: "Sinajana", value: "Sinajana" },
				{ label: "Socio", value: "Socio" },
				{ label: "South Acres", value: "South Acres" },
				{ label: "South Tipalao", value: "South Tipalao" },
				{ label: "Spring Hill Subdivision", value: "Spring Hill Subdivision" },
				{ label: "Sumay", value: "Sumay" },
				{ label: "Sumay Housing Area", value: "Sumay Housing Area" },
				{
					label: "Summer Place Subdivision",
					value: "Summer Place Subdivision",
				},
				{ label: "Tacpi", value: "Tacpi" },
				{ label: "Taguac", value: "Taguac" },
				{ label: "Tailalo", value: "Tailalo" },
				{ label: "Takano Subdivision", value: "Takano Subdivision" },
				{ label: "Talisay", value: "Talisay" },
				{ label: "Talofofo", value: "Talofofo" },
				{ label: "Talona", value: "Talona" },
				{ label: "Tamuneng", value: "Tamuneng" },
				{ label: "Tamuning", value: "Tamuning" },
				{ label: "Tarague (historical)", value: "Tarague (historical)" },
				{ label: "Tepungan", value: "Tepungan" },
				{ label: "Tijera", value: "Tijera" },
				{ label: "Tiyan", value: "Tiyan" },
				{ label: "Toa", value: "Toa" },
				{ label: "Toto", value: "Toto" },
				{ label: "Tres Reyes", value: "Tres Reyes" },
				{ label: "Tumalaglago", value: "Tumalaglago" },
				{ label: "Tumhun (historical)", value: "Tumhun (historical)" },
				{ label: "Tumon", value: "Tumon" },
				{ label: "Tumon Chama", value: "Tumon Chama" },
				{ label: "Tumon Heights", value: "Tumon Heights" },
				{ label: "Tutujan", value: "Tutujan" },
				{ label: "Umatac", value: "Umatac" },
				{ label: "Ungaguan", value: "Ungaguan" },
				{ label: "Upi (historical)", value: "Upi (historical)" },
				{ label: "Upper Kaiser", value: "Upper Kaiser" },
				{ label: "Utan", value: "Utan" },
				{ label: "Ututo", value: "Ututo" },
				{ label: "Wettengel Junction", value: "Wettengel Junction" },
				{
					label: "Windward Hills Subdivision",
					value: "Windward Hills Subdivision",
				},
				{ label: "Witek Junction", value: "Witek Junction" },
				{ label: "Yabai", value: "Yabai" },
				{ label: "Yigo", value: "Yigo" },
				{ label: "Yigo Estates", value: "Yigo Estates" },
				{ label: "Yona", value: "Yona" },
				{ label: "Ypan", value: "Ypan" },
				{ label: "Ypaopao Estates", value: "Ypaopao Estates" },
				// {label: "Тамунинг", value: "Тамунинг"},
				// {label: "تامونینگ", value: "تامونینگ"},
				// {label: "تامونینگ، گوام", value: "تامونینگ، گوام"},
				// {label: "タムニン", value: "タムニン"},
				// {label: "塔穆宁", value: "塔穆宁"},
				// {label: "타무닝", value: "타무닝"},
			],
		},
		{
			value: "HI",
			label: "Hawaii (HI)",
			json: [
				{ label: "Hanalei", value: "Hanalei" },
				{ label: "Hilo", value: "Hilo" },
				{ label: "Honaunau", value: "Honaunau" },
				{ label: "Honolulu", value: "Honolulu" },
				{ label: "Kahului", value: "Kahului" },
				{ label: "Kaneohe", value: "Kaneohe" },
				{ label: "Kapaa", value: "Kapaa" },
				{ label: "Kawaihae", value: "Kawaihae" },
				{ label: "Lahaina", value: "Lahaina" },
				{ label: "Laie", value: "Laie" },
				{ label: "Wahiawa", value: "Wahiawa" },
				{ label: "Wailuku", value: "Wailuku" },
				{ label: "Waimea", value: "Waimea" },
			],
		},
		{
			value: "ID",
			label: "Idaho (ID)",
			json: [
				{ label: "Blackfoot", value: "Blackfoot" },
				{ label: "Boise", value: "Boise" },
				{ label: "Bonners Ferry", value: "Bonners Ferry" },
				{ label: "Caldwell", value: "Caldwell" },
				{ label: "Coeur d’Alene", value: "Coeur d’Alene" },
				{ label: "Idaho City", value: "Idaho City" },
				{ label: "Idaho Falls", value: "Idaho Falls" },
				{ label: "Kellogg", value: "Kellogg" },
				{ label: "Lewiston", value: "Lewiston" },
				{ label: "Moscow", value: "Moscow" },
				{ label: "Nampa", value: "Nampa" },
				{ label: "Pocatello", value: "Pocatello" },
				{ label: "Priest River", value: "Priest River" },
				{ label: "Rexburg", value: "Rexburg" },
				{ label: "Sun Valley", value: "Sun Valley" },
				{ label: "Twin Falls", value: "Twin Falls" },
			],
		},
		{
			value: "IL",
			label: "Illinois (IL)",
			json: [
				{ label: "Alton", value: "Alton" },
				{ label: "Arlington Heights", value: "Arlington Heights" },
				{ label: "Arthur", value: "Arthur" },
				{ label: "Aurora", value: "Aurora" },
				{ label: "Belleville", value: "Belleville" },
				{ label: "Belvidere", value: "Belvidere" },
				{ label: "Bloomington", value: "Bloomington" },
				{ label: "Brookfield", value: "Brookfield" },
				{ label: "Cahokia", value: "Cahokia" },
				{ label: "Cairo", value: "Cairo" },
				{ label: "Calumet City", value: "Calumet City" },
				{ label: "Canton", value: "Canton" },
				{ label: "Carbondale", value: "Carbondale" },
				{ label: "Carlinville", value: "Carlinville" },
				{ label: "Carthage", value: "Carthage" },
				{ label: "Centralia", value: "Centralia" },
				{ label: "Champaign", value: "Champaign" },
				{ label: "Charleston", value: "Charleston" },
				{ label: "Chester", value: "Chester" },
				{ label: "Chicago", value: "Chicago" },
				{ label: "Chicago Heights", value: "Chicago Heights" },
				{ label: "Cicero", value: "Cicero" },
				{ label: "Collinsville", value: "Collinsville" },
				{ label: "Danville", value: "Danville" },
				{ label: "Decatur", value: "Decatur" },
				{ label: "DeKalb", value: "DeKalb" },
				{ label: "Des Plaines", value: "Des Plaines" },
				{ label: "Dixon", value: "Dixon" },
				{ label: "East Moline", value: "East Moline" },
				{ label: "East Saint Louis", value: "East Saint Louis" },
				{ label: "Effingham", value: "Effingham" },
				{ label: "Elgin", value: "Elgin" },
				{ label: "Elmhurst", value: "Elmhurst" },
				{ label: "Evanston", value: "Evanston" },
				{ label: "Freeport", value: "Freeport" },
				{ label: "Galena", value: "Galena" },
				{ label: "Galesburg", value: "Galesburg" },
				{ label: "Glen Ellyn", value: "Glen Ellyn" },
				{ label: "Glenview", value: "Glenview" },
				{ label: "Granite City", value: "Granite City" },
				{ label: "Harrisburg", value: "Harrisburg" },
				{ label: "Herrin", value: "Herrin" },
				{ label: "Highland Park", value: "Highland Park" },
				{ label: "Jacksonville", value: "Jacksonville" },
				{ label: "Joliet", value: "Joliet" },
				{ label: "Kankakee", value: "Kankakee" },
				{ label: "Kaskaskia", value: "Kaskaskia" },
				{ label: "Kewanee", value: "Kewanee" },
				{ label: "La Salle", value: "La Salle" },
				{ label: "Lake Forest", value: "Lake Forest" },
				{ label: "Libertyville", value: "Libertyville" },
				{ label: "Lincoln", value: "Lincoln" },
				{ label: "Lisle", value: "Lisle" },
				{ label: "Lombard", value: "Lombard" },
				{ label: "Macomb", value: "Macomb" },
				{ label: "Mattoon", value: "Mattoon" },
				{ label: "Moline", value: "Moline" },
				{ label: "Monmouth", value: "Monmouth" },
				{ label: "Mount Vernon", value: "Mount Vernon" },
				{ label: "Mundelein", value: "Mundelein" },
				{ label: "Naperville", value: "Naperville" },
				{ label: "Nauvoo", value: "Nauvoo" },
				{ label: "Normal", value: "Normal" },
				{ label: "North Chicago", value: "North Chicago" },
				{ label: "Oak Park", value: "Oak Park" },
				{ label: "Oregon", value: "Oregon" },
				{ label: "Ottawa", value: "Ottawa" },
				{ label: "Palatine", value: "Palatine" },
				{ label: "Park Forest", value: "Park Forest" },
				{ label: "Park Ridge", value: "Park Ridge" },
				{ label: "Pekin", value: "Pekin" },
				{ label: "Peoria", value: "Peoria" },
				{ label: "Petersburg", value: "Petersburg" },
				{ label: "Pontiac", value: "Pontiac" },
				{ label: "Quincy", value: "Quincy" },
				{ label: "Rantoul", value: "Rantoul" },
				{ label: "River Forest", value: "River Forest" },
				{ label: "Rock Island", value: "Rock Island" },
				{ label: "Rockford", value: "Rockford" },
				{ label: "Salem", value: "Salem" },
				{ label: "Shawneetown", value: "Shawneetown" },
				{ label: "Skokie", value: "Skokie" },
				{ label: "South Holland", value: "South Holland" },
				{ label: "Springfield", value: "Springfield" },
				{ label: "Streator", value: "Streator" },
				{ label: "Summit", value: "Summit" },
				{ label: "Urbana", value: "Urbana" },
				{ label: "Vandalia", value: "Vandalia" },
				{ label: "Virden", value: "Virden" },
				{ label: "Waukegan", value: "Waukegan" },
				{ label: "Wheaton", value: "Wheaton" },
				{ label: "Wilmette", value: "Wilmette" },
				{ label: "Winnetka", value: "Winnetka" },
				{ label: "Wood River", value: "Wood River" },
				{ label: "Zion", value: "Zion" },
			],
		},
		{
			value: "IN",
			label: "Indiana (IN)",
			json: [
				{ label: "Anderson", value: "Anderson" },
				{ label: "Bedford", value: "Bedford" },
				{ label: "Bloomington", value: "Bloomington" },
				{ label: "Columbus", value: "Columbus" },
				{ label: "Connersville", value: "Connersville" },
				{ label: "Corydon", value: "Corydon" },
				{ label: "Crawfordsville", value: "Crawfordsville" },
				{ label: "East Chicago", value: "East Chicago" },
				{ label: "Elkhart", value: "Elkhart" },
				{ label: "Elwood", value: "Elwood" },
				{ label: "Evansville", value: "Evansville" },
				{ label: "Fort Wayne", value: "Fort Wayne" },
				{ label: "French Lick", value: "French Lick" },
				{ label: "Gary", value: "Gary" },
				{ label: "Geneva", value: "Geneva" },
				{ label: "Goshen", value: "Goshen" },
				{ label: "Greenfield", value: "Greenfield" },
				{ label: "Hammond", value: "Hammond" },
				{ label: "Hobart", value: "Hobart" },
				{ label: "Huntington", value: "Huntington" },
				{ label: "Indianapolis", value: "Indianapolis" },
				{ label: "Jeffersonville", value: "Jeffersonville" },
				{ label: "Kokomo", value: "Kokomo" },
				{ label: "Lafayette", value: "Lafayette" },
				{ label: "Madison", value: "Madison" },
				{ label: "Marion", value: "Marion" },
				{ label: "Michigan City", value: "Michigan City" },
				{ label: "Mishawaka", value: "Mishawaka" },
				{ label: "Muncie", value: "Muncie" },
				{ label: "Nappanee", value: "Nappanee" },
				{ label: "Nashville", value: "Nashville" },
				{ label: "New Albany", value: "New Albany" },
				{ label: "New Castle", value: "New Castle" },
				{ label: "New Harmony", value: "New Harmony" },
				{ label: "Peru", value: "Peru" },
				{ label: "Plymouth", value: "Plymouth" },
				{ label: "Richmond", value: "Richmond" },
				{ label: "Santa Claus", value: "Santa Claus" },
				{ label: "Shelbyville", value: "Shelbyville" },
				{ label: "South Bend", value: "South Bend" },
				{ label: "Terre Haute", value: "Terre Haute" },
				{ label: "Valparaiso", value: "Valparaiso" },
				{ label: "Vincennes", value: "Vincennes" },
				{ label: "Wabash", value: "Wabash" },
				{ label: "West Lafayette", value: "West Lafayette" },
			],
		},
		{
			value: "IA",
			label: "Iowa (IA)",
			json: [
				{ label: "Amana Colonies", value: "Amana Colonies" },
				{ label: "Ames", value: "Ames" },
				{ label: "Boone", value: "Boone" },
				{ label: "Burlington", value: "Burlington" },
				{ label: "Cedar Falls", value: "Cedar Falls" },
				{ label: "Cedar Rapids", value: "Cedar Rapids" },
				{ label: "Charles City", value: "Charles City" },
				{ label: "Cherokee", value: "Cherokee" },
				{ label: "Clinton", value: "Clinton" },
				{ label: "Council Bluffs", value: "Council Bluffs" },
				{ label: "Davenport", value: "Davenport" },
				{ label: "Des Moines", value: "Des Moines" },
				{ label: "Dubuque", value: "Dubuque" },
				{ label: "Estherville", value: "Estherville" },
				{ label: "Fairfield", value: "Fairfield" },
				{ label: "Fort Dodge", value: "Fort Dodge" },
				{ label: "Grinnell", value: "Grinnell" },
				{ label: "Indianola", value: "Indianola" },
				{ label: "Iowa City", value: "Iowa City" },
				{ label: "Keokuk", value: "Keokuk" },
				{ label: "Mason City", value: "Mason City" },
				{ label: "Mount Pleasant", value: "Mount Pleasant" },
				{ label: "Muscatine", value: "Muscatine" },
				{ label: "Newton", value: "Newton" },
				{ label: "Oskaloosa", value: "Oskaloosa" },
				{ label: "Ottumwa", value: "Ottumwa" },
				{ label: "Sioux City", value: "Sioux City" },
				{ label: "Waterloo", value: "Waterloo" },
				{ label: "Webster City", value: "Webster City" },
				{ label: "West Des Moines", value: "West Des Moines" },
			],
		},
		{
			value: "KS",
			label: "Kansas (KS)",
			json: [
				{ label: "Abilene", value: "Abilene" },
				{ label: "Arkansas City", value: "Arkansas City" },
				{ label: "Atchison", value: "Atchison" },
				{ label: "Chanute", value: "Chanute" },
				{ label: "Coffeyville", value: "Coffeyville" },
				{ label: "Council Grove", value: "Council Grove" },
				{ label: "Dodge City", value: "Dodge City" },
				{ label: "Emporia", value: "Emporia" },
				{ label: "Fort Scott", value: "Fort Scott" },
				{ label: "Garden City", value: "Garden City" },
				{ label: "Great Bend", value: "Great Bend" },
				{ label: "Hays", value: "Hays" },
				{ label: "Hutchinson", value: "Hutchinson" },
				{ label: "Independence", value: "Independence" },
				{ label: "Junction City", value: "Junction City" },
				{ label: "Kansas City", value: "Kansas City" },
				{ label: "Lawrence", value: "Lawrence" },
				{ label: "Leavenworth", value: "Leavenworth" },
				{ label: "Liberal", value: "Liberal" },
				{ label: "Manhattan", value: "Manhattan" },
				{ label: "McPherson", value: "McPherson" },
				{ label: "Medicine Lodge", value: "Medicine Lodge" },
				{ label: "Newton", value: "Newton" },
				{ label: "Olathe", value: "Olathe" },
				{ label: "Osawatomie", value: "Osawatomie" },
				{ label: "Ottawa", value: "Ottawa" },
				{ label: "Overland Park", value: "Overland Park" },
				{ label: "Pittsburg", value: "Pittsburg" },
				{ label: "Salina", value: "Salina" },
				{ label: "Shawnee", value: "Shawnee" },
				{ label: "Smith Center", value: "Smith Center" },
				{ label: "Topeka", value: "Topeka" },
				{ label: "Wichita", value: "Wichita" },
			],
		},
		{
			value: "KY",
			label: "Kentucky (KY)",
			json: [
				{ label: "Ashland", value: "Ashland" },
				{ label: "Barbourville", value: "Barbourville" },
				{ label: "Bardstown", value: "Bardstown" },
				{ label: "Berea", value: "Berea" },
				{ label: "Boonesborough", value: "Boonesborough" },
				{ label: "Bowling Green", value: "Bowling Green" },
				{ label: "Campbellsville", value: "Campbellsville" },
				{ label: "Covington", value: "Covington" },
				{ label: "Danville", value: "Danville" },
				{ label: "Elizabethtown", value: "Elizabethtown" },
				{ label: "Frankfort", value: "Frankfort" },
				{ label: "Harlan", value: "Harlan" },
				{ label: "Harrodsburg", value: "Harrodsburg" },
				{ label: "Hazard", value: "Hazard" },
				{ label: "Henderson", value: "Henderson" },
				{ label: "Hodgenville", value: "Hodgenville" },
				{ label: "Hopkinsville", value: "Hopkinsville" },
				{ label: "Lexington", value: "Lexington" },
				{ label: "Louisville", value: "Louisville" },
				{ label: "Mayfield", value: "Mayfield" },
				{ label: "Maysville", value: "Maysville" },
				{ label: "Middlesboro", value: "Middlesboro" },
				{ label: "Newport", value: "Newport" },
				{ label: "Owensboro", value: "Owensboro" },
				{ label: "Paducah", value: "Paducah" },
				{ label: "Paris", value: "Paris" },
				{ label: "Richmond", value: "Richmond" },
			],
		},
		{
			value: "LA",
			label: "Louisiana (LA)",
			json: [
				{ label: "Abbeville", value: "Abbeville" },
				{ label: "Alexandria", value: "Alexandria" },
				{ label: "Bastrop", value: "Bastrop" },
				{ label: "Baton Rouge", value: "Baton Rouge" },
				{ label: "Bogalusa", value: "Bogalusa" },
				{ label: "Bossier City", value: "Bossier City" },
				{ label: "Gretna", value: "Gretna" },
				{ label: "Houma", value: "Houma" },
				{ label: "Lafayette", value: "Lafayette" },
				{ label: "Lake Charles", value: "Lake Charles" },
				{ label: "Monroe", value: "Monroe" },
				{ label: "Morgan City", value: "Morgan City" },
				{ label: "Natchitoches", value: "Natchitoches" },
				{ label: "New Iberia", value: "New Iberia" },
				{ label: "New Orleans", value: "New Orleans" },
				{ label: "Opelousas", value: "Opelousas" },
				{ label: "Ruston", value: "Ruston" },
				{ label: "Saint Martinville", value: "Saint Martinville" },
				{ label: "Shreveport", value: "Shreveport" },
				{ label: "Thibodaux", value: "Thibodaux" },
			],
		},
		{
			value: "ME",
			label: "Maine (ME)",
			json: [
				{ label: "Auburn", value: "Auburn" },
				{ label: "Augusta", value: "Augusta" },
				{ label: "Bangor", value: "Bangor" },
				{ label: "Bar Harbor", value: "Bar Harbor" },
				{ label: "Bath", value: "Bath" },
				{ label: "Belfast", value: "Belfast" },
				{ label: "Biddeford", value: "Biddeford" },
				{ label: "Boothbay Harbor", value: "Boothbay Harbor" },
				{ label: "Brunswick", value: "Brunswick" },
				{ label: "Calais", value: "Calais" },
				{ label: "Caribou", value: "Caribou" },
				{ label: "Castine", value: "Castine" },
				{ label: "Eastport", value: "Eastport" },
				{ label: "Ellsworth", value: "Ellsworth" },
				{ label: "Farmington", value: "Farmington" },
				{ label: "Fort Kent", value: "Fort Kent" },
				{ label: "Gardiner", value: "Gardiner" },
				{ label: "Houlton", value: "Houlton" },
				{ label: "Kennebunkport", value: "Kennebunkport" },
				{ label: "Kittery", value: "Kittery" },
				{ label: "Lewiston", value: "Lewiston" },
				{ label: "Lubec", value: "Lubec" },
				{ label: "Machias", value: "Machias" },
				{ label: "Orono", value: "Orono" },
				{ label: "Portland", value: "Portland" },
				{ label: "Presque Isle", value: "Presque Isle" },
				{ label: "Rockland", value: "Rockland" },
				{ label: "Rumford", value: "Rumford" },
				{ label: "Saco", value: "Saco" },
				{ label: "Scarborough", value: "Scarborough" },
				{ label: "Waterville", value: "Waterville" },
				{ label: "York", value: "York" },
			],
		},
		{
			value: "MD",
			label: "Maryland (MD)",
			json: [
				{ label: "Aberdeen", value: "Aberdeen" },
				{ label: "Annapolis", value: "Annapolis" },
				{ label: "Baltimore", value: "Baltimore" },
				{ label: "Bethesda-Chevy Chase", value: "Bethesda-Chevy Chase" },
				{ label: "Bowie", value: "Bowie" },
				{ label: "Cambridge", value: "Cambridge" },
				{ label: "Catonsville", value: "Catonsville" },
				{ label: "College Park", value: "College Park" },
				{ label: "Columbia", value: "Columbia" },
				{ label: "Cumberland", value: "Cumberland" },
				{ label: "Easton", value: "Easton" },
				{ label: "Elkton", value: "Elkton" },
				{ label: "Emmitsburg", value: "Emmitsburg" },
				{ label: "Frederick", value: "Frederick" },
				{ label: "Greenbelt", value: "Greenbelt" },
				{ label: "Hagerstown", value: "Hagerstown" },
				{ label: "Hyattsville", value: "Hyattsville" },
				{ label: "Laurel", value: "Laurel" },
				{ label: "Oakland", value: "Oakland" },
				{ label: "Ocean City", value: "Ocean City" },
				{ label: "Rockville", value: "Rockville" },
				{ label: "Saint Marys City", value: "Saint Marys City" },
				{ label: "Salisbury", value: "Salisbury" },
				{ label: "Silver Spring", value: "Silver Spring" },
				{ label: "Takoma Park", value: "Takoma Park" },
				{ label: "Towson", value: "Towson" },
				{ label: "Westminster", value: "Westminster" },
			],
		},
		{
			value: "MA",
			label: "Massachusetts (MA)",
			json: [
				{ label: "Abington", value: "Abington" },
				{ label: "Adams", value: "Adams" },
				{ label: "Amesbury", value: "Amesbury" },
				{ label: "Amherst", value: "Amherst" },
				{ label: "Andover", value: "Andover" },
				{ label: "Arlington", value: "Arlington" },
				{ label: "Athol", value: "Athol" },
				{ label: "Attleboro", value: "Attleboro" },
				{ label: "Barnstable", value: "Barnstable" },
				{ label: "Bedford", value: "Bedford" },
				{ label: "Beverly", value: "Beverly" },
				{ label: "Boston", value: "Boston" },
				{ label: "Bourne", value: "Bourne" },
				{ label: "Braintree", value: "Braintree" },
				{ label: "Brockton", value: "Brockton" },
				{ label: "Brookline", value: "Brookline" },
				{ label: "Cambridge", value: "Cambridge" },
				{ label: "Canton", value: "Canton" },
				{ label: "Charlestown", value: "Charlestown" },
				{ label: "Chelmsford", value: "Chelmsford" },
				{ label: "Chelsea", value: "Chelsea" },
				{ label: "Chicopee", value: "Chicopee" },
				{ label: "Clinton", value: "Clinton" },
				{ label: "Cohasset", value: "Cohasset" },
				{ label: "Concord", value: "Concord" },
				{ label: "Danvers", value: "Danvers" },
				{ label: "Dartmouth", value: "Dartmouth" },
				{ label: "Dedham", value: "Dedham" },
				{ label: "Dennis", value: "Dennis" },
				{ label: "Duxbury", value: "Duxbury" },
				{ label: "Eastham", value: "Eastham" },
				{ label: "Edgartown", value: "Edgartown" },
				{ label: "Everett", value: "Everett" },
				{ label: "Fairhaven", value: "Fairhaven" },
				{ label: "Fall River", value: "Fall River" },
				{ label: "Falmouth", value: "Falmouth" },
				{ label: "Fitchburg", value: "Fitchburg" },
				{ label: "Framingham", value: "Framingham" },
				{ label: "Gloucester", value: "Gloucester" },
				{ label: "Great Barrington", value: "Great Barrington" },
				{ label: "Greenfield", value: "Greenfield" },
				{ label: "Groton", value: "Groton" },
				{ label: "Harwich", value: "Harwich" },
				{ label: "Haverhill", value: "Haverhill" },
				{ label: "Hingham", value: "Hingham" },
				{ label: "Holyoke", value: "Holyoke" },
				{ label: "Hyannis", value: "Hyannis" },
				{ label: "Ipswich", value: "Ipswich" },
				{ label: "Lawrence", value: "Lawrence" },
				{ label: "Lenox", value: "Lenox" },
				{ label: "Leominster", value: "Leominster" },
				{ label: "Lexington", value: "Lexington" },
				{ label: "Lowell", value: "Lowell" },
				{ label: "Ludlow", value: "Ludlow" },
				{ label: "Lynn", value: "Lynn" },
				{ label: "Malden", value: "Malden" },
				{ label: "Marblehead", value: "Marblehead" },
				{ label: "Marlborough", value: "Marlborough" },
				{ label: "Medford", value: "Medford" },
				{ label: "Milton", value: "Milton" },
				{ label: "Nahant", value: "Nahant" },
				{ label: "Natick", value: "Natick" },
				{ label: "New Bedford", value: "New Bedford" },
				{ label: "Newburyport", value: "Newburyport" },
				{ label: "Newton", value: "Newton" },
				{ label: "North Adams", value: "North Adams" },
				{ label: "Northampton", value: "Northampton" },
				{ label: "Norton", value: "Norton" },
				{ label: "Norwood", value: "Norwood" },
				{ label: "Peabody", value: "Peabody" },
				{ label: "Pittsfield", value: "Pittsfield" },
				{ label: "Plymouth", value: "Plymouth" },
				{ label: "Provincetown", value: "Provincetown" },
				{ label: "Quincy", value: "Quincy" },
				{ label: "Randolph", value: "Randolph" },
				{ label: "Revere", value: "Revere" },
				{ label: "Salem", value: "Salem" },
				{ label: "Sandwich", value: "Sandwich" },
				{ label: "Saugus", value: "Saugus" },
				{ label: "Somerville", value: "Somerville" },
				{ label: "South Hadley", value: "South Hadley" },
				{ label: "Springfield", value: "Springfield" },
				{ label: "Stockbridge", value: "Stockbridge" },
				{ label: "Stoughton", value: "Stoughton" },
				{ label: "Sturbridge", value: "Sturbridge" },
				{ label: "Sudbury", value: "Sudbury" },
				{ label: "Taunton", value: "Taunton" },
				{ label: "Tewksbury", value: "Tewksbury" },
				{ label: "Truro", value: "Truro" },
				{ label: "Watertown", value: "Watertown" },
				{ label: "Webster", value: "Webster" },
				{ label: "Wellesley", value: "Wellesley" },
				{ label: "Wellfleet", value: "Wellfleet" },
				{ label: "West Bridgewater", value: "West Bridgewater" },
				{ label: "West Springfield", value: "West Springfield" },
				{ label: "Westfield", value: "Westfield" },
				{ label: "Weymouth", value: "Weymouth" },
				{ label: "Whitman", value: "Whitman" },
				{ label: "Williamstown", value: "Williamstown" },
				{ label: "Woburn", value: "Woburn" },
				{ label: "Woods Hole", value: "Woods Hole" },
				{ label: "Worcester", value: "Worcester" },
			],
		},
		{
			value: "MI",
			label: "Michigan (MI)",
			json: [
				{ label: "Adrian", value: "Adrian" },
				{ label: "Alma", value: "Alma" },
				{ label: "Ann Arbor", value: "Ann Arbor" },
				{ label: "Battle Creek", value: "Battle Creek" },
				{ label: "Bay City", value: "Bay City" },
				{ label: "Benton Harbor", value: "Benton Harbor" },
				{ label: "Bloomfield Hills", value: "Bloomfield Hills" },
				{ label: "Cadillac", value: "Cadillac" },
				{ label: "Charlevoix", value: "Charlevoix" },
				{ label: "Cheboygan", value: "Cheboygan" },
				{ label: "Dearborn", value: "Dearborn" },
				{ label: "Detroit", value: "Detroit" },
				{ label: "East Lansing", value: "East Lansing" },
				{ label: "Eastpointe", value: "Eastpointe" },
				{ label: "Ecorse", value: "Ecorse" },
				{ label: "Escanaba", value: "Escanaba" },
				{ label: "Flint", value: "Flint" },
				{ label: "Grand Haven", value: "Grand Haven" },
				{ label: "Grand Rapids", value: "Grand Rapids" },
				{ label: "Grayling", value: "Grayling" },
				{ label: "Grosse Pointe", value: "Grosse Pointe" },
				{ label: "Hancock", value: "Hancock" },
				{ label: "Highland Park", value: "Highland Park" },
				{ label: "Holland", value: "Holland" },
				{ label: "Houghton", value: "Houghton" },
				{ label: "Interlochen", value: "Interlochen" },
				{ label: "Iron Mountain", value: "Iron Mountain" },
				{ label: "Ironwood", value: "Ironwood" },
				{ label: "Ishpeming", value: "Ishpeming" },
				{ label: "Jackson", value: "Jackson" },
				{ label: "Kalamazoo", value: "Kalamazoo" },
				{ label: "Lansing", value: "Lansing" },
				{ label: "Livonia", value: "Livonia" },
				{ label: "Ludington", value: "Ludington" },
				{ label: "Mackinaw City", value: "Mackinaw City" },
				{ label: "Manistee", value: "Manistee" },
				{ label: "Marquette", value: "Marquette" },
				{ label: "Menominee", value: "Menominee" },
				{ label: "Midland", value: "Midland" },
				{ label: "Monroe", value: "Monroe" },
				{ label: "Mount Clemens", value: "Mount Clemens" },
				{ label: "Mount Pleasant", value: "Mount Pleasant" },
				{ label: "Muskegon", value: "Muskegon" },
				{ label: "Niles", value: "Niles" },
				{ label: "Petoskey", value: "Petoskey" },
				{ label: "Pontiac", value: "Pontiac" },
				{ label: "Port Huron", value: "Port Huron" },
				{ label: "Royal Oak", value: "Royal Oak" },
				{ label: "Saginaw", value: "Saginaw" },
				{ label: "Saint Ignace", value: "Saint Ignace" },
				{ label: "Saint Joseph", value: "Saint Joseph" },
				{ label: "Sault Sainte Marie", value: "Sault Sainte Marie" },
				{ label: "Traverse City", value: "Traverse City" },
				{ label: "Trenton", value: "Trenton" },
				{ label: "Warren", value: "Warren" },
				{ label: "Wyandotte", value: "Wyandotte" },
				{ label: "Ypsilanti", value: "Ypsilanti" },
			],
		},
		{
			value: "MN",
			label: "Minnesota (MN)",
			json: [
				{ label: "Albert Lea", value: "Albert Lea" },
				{ label: "Alexandria", value: "Alexandria" },
				{ label: "Austin", value: "Austin" },
				{ label: "Bemidji", value: "Bemidji" },
				{ label: "Bloomington", value: "Bloomington" },
				{ label: "Brainerd", value: "Brainerd" },
				{ label: "Crookston", value: "Crookston" },
				{ label: "Duluth", value: "Duluth" },
				{ label: "Ely", value: "Ely" },
				{ label: "Eveleth", value: "Eveleth" },
				{ label: "Faribault", value: "Faribault" },
				{ label: "Fergus Falls", value: "Fergus Falls" },
				{ label: "Hastings", value: "Hastings" },
				{ label: "Hibbing", value: "Hibbing" },
				{ label: "International Falls", value: "International Falls" },
				{ label: "Little Falls", value: "Little Falls" },
				{ label: "Mankato", value: "Mankato" },
				{ label: "Minneapolis", value: "Minneapolis" },
				{ label: "Moorhead", value: "Moorhead" },
				{ label: "New Ulm", value: "New Ulm" },
				{ label: "Northfield", value: "Northfield" },
				{ label: "Owatonna", value: "Owatonna" },
				{ label: "Pipestone", value: "Pipestone" },
				{ label: "Red Wing", value: "Red Wing" },
				{ label: "Rochester", value: "Rochester" },
				{ label: "Saint Cloud", value: "Saint Cloud" },
				{ label: "Saint Paul", value: "Saint Paul" },
				{ label: "Sauk Centre", value: "Sauk Centre" },
				{ label: "South Saint Paul", value: "South Saint Paul" },
				{ label: "Stillwater", value: "Stillwater" },
				{ label: "Virginia", value: "Virginia" },
				{ label: "Willmar", value: "Willmar" },
				{ label: "Winona", value: "Winona" },
			],
		},
		{
			value: "MS",
			label: "Mississippi (MS)",
			json: [
				{ label: "Bay Saint Louis", value: "Bay Saint Louis" },
				{ label: "Biloxi", value: "Biloxi" },
				{ label: "Canton", value: "Canton" },
				{ label: "Clarksdale", value: "Clarksdale" },
				{ label: "Columbia", value: "Columbia" },
				{ label: "Columbus", value: "Columbus" },
				{ label: "Corinth", value: "Corinth" },
				{ label: "Greenville", value: "Greenville" },
				{ label: "Greenwood", value: "Greenwood" },
				{ label: "Grenada", value: "Grenada" },
				{ label: "Gulfport", value: "Gulfport" },
				{ label: "Hattiesburg", value: "Hattiesburg" },
				{ label: "Holly Springs", value: "Holly Springs" },
				{ label: "Jackson", value: "Jackson" },
				{ label: "Laurel", value: "Laurel" },
				{ label: "Meridian", value: "Meridian" },
				{ label: "Natchez", value: "Natchez" },
				{ label: "Ocean Springs", value: "Ocean Springs" },
				{ label: "Oxford", value: "Oxford" },
				{ label: "Pascagoula", value: "Pascagoula" },
				{ label: "Pass Christian", value: "Pass Christian" },
				{ label: "Philadelphia", value: "Philadelphia" },
				{ label: "Port Gibson", value: "Port Gibson" },
				{ label: "Starkville", value: "Starkville" },
				{ label: "Tupelo", value: "Tupelo" },
				{ label: "Vicksburg", value: "Vicksburg" },
				{ label: "West Point", value: "West Point" },
				{ label: "Yazoo City", value: "Yazoo City" },
			],
		},
		{
			value: "MO",
			label: "Missouri (MO)",
			json: [
				{ label: "Boonville", value: "Boonville" },
				{ label: "Branson", value: "Branson" },
				{ label: "Cape Girardeau", value: "Cape Girardeau" },
				{ label: "Carthage", value: "Carthage" },
				{ label: "Chillicothe", value: "Chillicothe" },
				{ label: "Clayton", value: "Clayton" },
				{ label: "Columbia", value: "Columbia" },
				{ label: "Excelsior Springs", value: "Excelsior Springs" },
				{ label: "Ferguson", value: "Ferguson" },
				{ label: "Florissant", value: "Florissant" },
				{ label: "Fulton", value: "Fulton" },
				{ label: "Hannibal", value: "Hannibal" },
				{ label: "Independence", value: "Independence" },
				{ label: "Jefferson City", value: "Jefferson City" },
				{ label: "Joplin", value: "Joplin" },
				{ label: "Kansas City", value: "Kansas City" },
				{ label: "Kirksville", value: "Kirksville" },
				{ label: "Lamar", value: "Lamar" },
				{ label: "Lebanon", value: "Lebanon" },
				{ label: "Lexington", value: "Lexington" },
				{ label: "Maryville", value: "Maryville" },
				{ label: "Mexico", value: "Mexico" },
				{ label: "Monett", value: "Monett" },
				{ label: "Neosho", value: "Neosho" },
				{ label: "New Madrid", value: "New Madrid" },
				{ label: "Rolla", value: "Rolla" },
				{ label: "Saint Charles", value: "Saint Charles" },
				{ label: "Saint Joseph", value: "Saint Joseph" },
				{ label: "Saint Louis", value: "Saint Louis" },
				{ label: "Sainte Genevieve", value: "Sainte Genevieve" },
				{ label: "Salem", value: "Salem" },
				{ label: "Sedalia", value: "Sedalia" },
				{ label: "Springfield", value: "Springfield" },
				{ label: "Warrensburg", value: "Warrensburg" },
				{ label: "West Plains", value: "West Plains" },
			],
		},
		{
			value: "MT",
			label: "Montana (MT)",
			json: [
				{ label: "Anaconda", value: "Anaconda" },
				{ label: "Billings", value: "Billings" },
				{ label: "Bozeman", value: "Bozeman" },
				{ label: "Butte", value: "Butte" },
				{ label: "Dillon", value: "Dillon" },
				{ label: "Fort Benton", value: "Fort Benton" },
				{ label: "Glendive", value: "Glendive" },
				{ label: "Great Falls", value: "Great Falls" },
				{ label: "Havre", value: "Havre" },
				{ label: "Helena", value: "Helena" },
				{ label: "Kalispell", value: "Kalispell" },
				{ label: "Lewistown", value: "Lewistown" },
				{ label: "Livingston", value: "Livingston" },
				{ label: "Miles City", value: "Miles City" },
				{ label: "Missoula", value: "Missoula" },
				{ label: "Virginia City", value: "Virginia City" },
			],
		},
		{
			value: "NE",
			label: "Nebraska (NE)",
			json: [
				{ label: "Beatrice", value: "Beatrice" },
				{ label: "Bellevue", value: "Bellevue" },
				{ label: "Boys Town", value: "Boys Town" },
				{ label: "Chadron", value: "Chadron" },
				{ label: "Columbus", value: "Columbus" },
				{ label: "Fremont", value: "Fremont" },
				{ label: "Grand Island", value: "Grand Island" },
				{ label: "Hastings", value: "Hastings" },
				{ label: "Kearney", value: "Kearney" },
				{ label: "Lincoln", value: "Lincoln" },
				{ label: "McCook", value: "McCook" },
				{ label: "Minden", value: "Minden" },
				{ label: "Nebraska City", value: "Nebraska City" },
				{ label: "Norfolk", value: "Norfolk" },
				{ label: "North Platte", value: "North Platte" },
				{ label: "Omaha", value: "Omaha" },
				{ label: "Plattsmouth", value: "Plattsmouth" },
				{ label: "Red Cloud", value: "Red Cloud" },
				{ label: "Sidney", value: "Sidney" },
			],
		},
		{
			value: "NV",
			label: "Nevada (NV)",
			json: [
				{ label: "Boulder City", value: "Boulder City" },
				{ label: "Carson City", value: "Carson City" },
				{ label: "Elko", value: "Elko" },
				{ label: "Ely", value: "Ely" },
				{ label: "Fallon", value: "Fallon" },
				{ label: "Genoa", value: "Genoa" },
				{ label: "Goldfield", value: "Goldfield" },
				{ label: "Henderson", value: "Henderson" },
				{ label: "Las Vegas", value: "Las Vegas" },
				{ label: "North Las Vegas", value: "North Las Vegas" },
				{ label: "Reno", value: "Reno" },
				{ label: "Sparks", value: "Sparks" },
				{ label: "Virginia City", value: "Virginia City" },
				{ label: "Winnemucca", value: "Winnemucca" },
			],
		},
		{
			value: "NH",
			label: "New Hampshire (NH)",
			json: [
				{ label: "Berlin", value: "Berlin" },
				{ label: "Claremont", value: "Claremont" },
				{ label: "Concord", value: "Concord" },
				{ label: "Derry", value: "Derry" },
				{ label: "Dover", value: "Dover" },
				{ label: "Durham", value: "Durham" },
				{ label: "Exeter", value: "Exeter" },
				{ label: "Franklin", value: "Franklin" },
				{ label: "Hanover", value: "Hanover" },
				{ label: "Hillsborough", value: "Hillsborough" },
				{ label: "Keene", value: "Keene" },
				{ label: "Laconia", value: "Laconia" },
				{ label: "Lebanon", value: "Lebanon" },
				{ label: "Manchester", value: "Manchester" },
				{ label: "Nashua", value: "Nashua" },
				{ label: "Peterborough", value: "Peterborough" },
				{ label: "Plymouth", value: "Plymouth" },
				{ label: "Portsmouth", value: "Portsmouth" },
				{ label: "Rochester", value: "Rochester" },
				{ label: "Salem", value: "Salem" },
				{ label: "Somersworth", value: "Somersworth" },
			],
		},
		{
			value: "NJ",
			label: "New Jersey (NJ)",
			json: [
				{ label: "Asbury Park", value: "Asbury Park" },
				{ label: "Atlantic City", value: "Atlantic City" },
				{ label: "Bayonne", value: "Bayonne" },
				{ label: "Bloomfield", value: "Bloomfield" },
				{ label: "Bordentown", value: "Bordentown" },
				{ label: "Bound Brook", value: "Bound Brook" },
				{ label: "Bridgeton", value: "Bridgeton" },
				{ label: "Burlington", value: "Burlington" },
				{ label: "Caldwell", value: "Caldwell" },
				{ label: "Camden", value: "Camden" },
				{ label: "Cape May", value: "Cape May" },
				{ label: "Clifton", value: "Clifton" },
				{ label: "Cranford", value: "Cranford" },
				{ label: "East Orange", value: "East Orange" },
				{ label: "Edison", value: "Edison" },
				{ label: "Elizabeth", value: "Elizabeth" },
				{ label: "Englewood", value: "Englewood" },
				{ label: "Fort Lee", value: "Fort Lee" },
				{ label: "Glassboro", value: "Glassboro" },
				{ label: "Hackensack", value: "Hackensack" },
				{ label: "Haddonfield", value: "Haddonfield" },
				{ label: "Hoboken", value: "Hoboken" },
				{ label: "Irvington", value: "Irvington" },
				{ label: "Jersey City", value: "Jersey City" },
				{ label: "Lakehurst", value: "Lakehurst" },
				{ label: "Lakewood", value: "Lakewood" },
				{ label: "Long Beach", value: "Long Beach" },
				{ label: "Long Branch", value: "Long Branch" },
				{ label: "Madison", value: "Madison" },
				{ label: "Menlo Park", value: "Menlo Park" },
				{ label: "Millburn", value: "Millburn" },
				{ label: "Millville", value: "Millville" },
				{ label: "Montclair", value: "Montclair" },
				{ label: "Morristown", value: "Morristown" },
				{ label: "Mount Holly", value: "Mount Holly" },
				{ label: "New Brunswick", value: "New Brunswick" },
				{ label: "New Milford", value: "New Milford" },
				{ label: "Newark", value: "Newark" },
				{ label: "Ocean City", value: "Ocean City" },
				{ label: "Orange", value: "Orange" },
				{ label: "Parsippany–Troy Hills", value: "Parsippany–Troy Hills" },
				{ label: "Passaic", value: "Passaic" },
				{ label: "Paterson", value: "Paterson" },
				{ label: "Perth Amboy", value: "Perth Amboy" },
				{ label: "Plainfield", value: "Plainfield" },
				{ label: "Princeton", value: "Princeton" },
				{ label: "Ridgewood", value: "Ridgewood" },
				{ label: "Roselle", value: "Roselle" },
				{ label: "Rutherford", value: "Rutherford" },
				{ label: "Salem", value: "Salem" },
				{ label: "Somerville", value: "Somerville" },
				{ label: "South Orange Village", value: "South Orange Village" },
				{ label: "Totowa", value: "Totowa" },
				{ label: "Trenton", value: "Trenton" },
				{ label: "Union", value: "Union" },
				{ label: "Union City", value: "Union City" },
				{ label: "Vineland", value: "Vineland" },
				{ label: "Wayne", value: "Wayne" },
				{ label: "Weehawken", value: "Weehawken" },
				{ label: "West New York", value: "West New York" },
				{ label: "West Orange", value: "West Orange" },
				{ label: "Willingboro", value: "Willingboro" },
				{ label: "Woodbridge", value: "Woodbridge" },
			],
		},
		{
			value: "NM",
			label: "New Mexico (NM)",
			json: [
				{ label: "Acoma", value: "Acoma" },
				{ label: "Alamogordo", value: "Alamogordo" },
				{ label: "Albuquerque", value: "Albuquerque" },
				{ label: "Artesia", value: "Artesia" },
				{ label: "Belen", value: "Belen" },
				{ label: "Carlsbad", value: "Carlsbad" },
				{ label: "Clovis", value: "Clovis" },
				{ label: "Deming", value: "Deming" },
				{ label: "Farmington", value: "Farmington" },
				{ label: "Gallup", value: "Gallup" },
				{ label: "Grants", value: "Grants" },
				{ label: "Hobbs", value: "Hobbs" },
				{ label: "Las Cruces", value: "Las Cruces" },
				{ label: "Las Vegas", value: "Las Vegas" },
				{ label: "Los Alamos", value: "Los Alamos" },
				{ label: "Lovington", value: "Lovington" },
				{ label: "Portales", value: "Portales" },
				{ label: "Raton", value: "Raton" },
				{ label: "Roswell", value: "Roswell" },
				{ label: "Santa Fe", value: "Santa Fe" },
				{ label: "Shiprock", value: "Shiprock" },
				{ label: "Silver City", value: "Silver City" },
				{ label: "Socorro", value: "Socorro" },
				{ label: "Taos", value: "Taos" },
				{ label: "Truth or Consequences", value: "Truth or Consequences" },
				{ label: "Tucumcari", value: "Tucumcari" },
			],
		},
		{
			value: "NY",
			label: "New York (NY)",
			json: [
				{ label: "Albany", value: "Albany" },
				{ label: "Amsterdam", value: "Amsterdam" },
				{ label: "Auburn", value: "Auburn" },
				{ label: "Babylon", value: "Babylon" },
				{ label: "Batavia", value: "Batavia" },
				{ label: "Beacon", value: "Beacon" },
				{ label: "Bedford", value: "Bedford" },
				{ label: "Binghamton", value: "Binghamton" },
				{ label: "Bronx", value: "Bronx" },
				{ label: "Brooklyn", value: "Brooklyn" },
				{ label: "Buffalo", value: "Buffalo" },
				{ label: "Chautauqua", value: "Chautauqua" },
				{ label: "Cheektowaga", value: "Cheektowaga" },
				{ label: "Clinton", value: "Clinton" },
				{ label: "Cohoes", value: "Cohoes" },
				{ label: "Coney Island", value: "Coney Island" },
				{ label: "Cooperstown", value: "Cooperstown" },
				{ label: "Corning", value: "Corning" },
				{ label: "Cortland", value: "Cortland" },
				{ label: "Crown Point", value: "Crown Point" },
				{ label: "Dunkirk", value: "Dunkirk" },
				{ label: "East Aurora", value: "East Aurora" },
				{ label: "East Hampton", value: "East Hampton" },
				{ label: "Eastchester", value: "Eastchester" },
				{ label: "Elmira", value: "Elmira" },
				{ label: "Flushing", value: "Flushing" },
				{ label: "Forest Hills", value: "Forest Hills" },
				{ label: "Fredonia", value: "Fredonia" },
				{ label: "Garden City", value: "Garden City" },
				{ label: "Geneva", value: "Geneva" },
				{ label: "Glens Falls", value: "Glens Falls" },
				{ label: "Gloversville", value: "Gloversville" },
				{ label: "Great Neck", value: "Great Neck" },
				{ label: "Hammondsport", value: "Hammondsport" },
				{ label: "Harlem", value: "Harlem" },
				{ label: "Hempstead", value: "Hempstead" },
				{ label: "Herkimer", value: "Herkimer" },
				{ label: "Hudson", value: "Hudson" },
				{ label: "Huntington", value: "Huntington" },
				{ label: "Hyde Park", value: "Hyde Park" },
				{ label: "Ilion", value: "Ilion" },
				{ label: "Ithaca", value: "Ithaca" },
				{ label: "Jamestown", value: "Jamestown" },
				{ label: "Johnstown", value: "Johnstown" },
				{ label: "Kingston", value: "Kingston" },
				{ label: "Lackawanna", value: "Lackawanna" },
				{ label: "Lake Placid", value: "Lake Placid" },
				{ label: "Levittown", value: "Levittown" },
				{ label: "Lockport", value: "Lockport" },
				{ label: "Mamaroneck", value: "Mamaroneck" },
				{ label: "Manhattan", value: "Manhattan" },
				{ label: "Massena", value: "Massena" },
				{ label: "Middletown", value: "Middletown" },
				{ label: "Mineola", value: "Mineola" },
				{ label: "Mount Vernon", value: "Mount Vernon" },
				{ label: "New Paltz", value: "New Paltz" },
				{ label: "New Rochelle", value: "New Rochelle" },
				{ label: "New Windsor", value: "New Windsor" },
				{ label: "New York City", value: "New York City" },
				{ label: "Newburgh", value: "Newburgh" },
				{ label: "Niagara Falls", value: "Niagara Falls" },
				{ label: "North Hempstead", value: "North Hempstead" },
				{ label: "Nyack", value: "Nyack" },
				{ label: "Ogdensburg", value: "Ogdensburg" },
				{ label: "Olean", value: "Olean" },
				{ label: "Oneida", value: "Oneida" },
				{ label: "Oneonta", value: "Oneonta" },
				{ label: "Ossining", value: "Ossining" },
				{ label: "Oswego", value: "Oswego" },
				{ label: "Oyster Bay", value: "Oyster Bay" },
				{ label: "Palmyra", value: "Palmyra" },
				{ label: "Peekskill", value: "Peekskill" },
				{ label: "Plattsburgh", value: "Plattsburgh" },
				{ label: "Port Washington", value: "Port Washington" },
				{ label: "Potsdam", value: "Potsdam" },
				{ label: "Poughkeepsie", value: "Poughkeepsie" },
				{ label: "Queens", value: "Queens" },
				{ label: "Rensselaer", value: "Rensselaer" },
				{ label: "Rochester", value: "Rochester" },
				{ label: "Rome", value: "Rome" },
				{ label: "Rotterdam", value: "Rotterdam" },
				{ label: "Rye", value: "Rye" },
				{ label: "Sag Harbor", value: "Sag Harbor" },
				{ label: "Saranac Lake", value: "Saranac Lake" },
				{ label: "Saratoga Springs", value: "Saratoga Springs" },
				{ label: "Scarsdale", value: "Scarsdale" },
				{ label: "Schenectady", value: "Schenectady" },
				{ label: "Seneca Falls", value: "Seneca Falls" },
				{ label: "Southampton", value: "Southampton" },
				{ label: "Staten Island", value: "Staten Island" },
				{ label: "Stony Brook", value: "Stony Brook" },
				{ label: "Stony Point", value: "Stony Point" },
				{ label: "Syracuse", value: "Syracuse" },
				{ label: "Tarrytown", value: "Tarrytown" },
				{ label: "Ticonderoga", value: "Ticonderoga" },
				{ label: "Tonawanda", value: "Tonawanda" },
				{ label: "Troy", value: "Troy" },
				{ label: "Utica", value: "Utica" },
				{ label: "Watertown", value: "Watertown" },
				{ label: "Watervliet", value: "Watervliet" },
				{ label: "Watkins Glen", value: "Watkins Glen" },
				{ label: "West Seneca", value: "West Seneca" },
				{ label: "White Plains", value: "White Plains" },
				{ label: "Woodstock", value: "Woodstock" },
				{ label: "Yonkers", value: "Yonkers" },
			],
		},
		{
			value: "NC",
			label: "North Carolina (NC)",
			json: [
				{ label: "Asheboro", value: "Asheboro" },
				{ label: "Asheville", value: "Asheville" },
				{ label: "Bath", value: "Bath" },
				{ label: "Beaufort", value: "Beaufort" },
				{ label: "Boone", value: "Boone" },
				{ label: "Burlington", value: "Burlington" },
				{ label: "Chapel Hill", value: "Chapel Hill" },
				{ label: "Charlotte", value: "Charlotte" },
				{ label: "Concord", value: "Concord" },
				{ label: "Durham", value: "Durham" },
				{ label: "Edenton", value: "Edenton" },
				{ label: "Elizabeth City", value: "Elizabeth City" },
				{ label: "Fayetteville", value: "Fayetteville" },
				{ label: "Gastonia", value: "Gastonia" },
				{ label: "Goldsboro", value: "Goldsboro" },
				{ label: "Greensboro", value: "Greensboro" },
				{ label: "Greenville", value: "Greenville" },
				{ label: "Halifax", value: "Halifax" },
				{ label: "Henderson", value: "Henderson" },
				{ label: "Hickory", value: "Hickory" },
				{ label: "High Point", value: "High Point" },
				{ label: "Hillsborough", value: "Hillsborough" },
				{ label: "Jacksonville", value: "Jacksonville" },
				{ label: "Kinston", value: "Kinston" },
				{ label: "Kitty Hawk", value: "Kitty Hawk" },
				{ label: "Lumberton", value: "Lumberton" },
				{ label: "Morehead City", value: "Morehead City" },
				{ label: "Morganton", value: "Morganton" },
				{ label: "Nags Head", value: "Nags Head" },
				{ label: "New Bern", value: "New Bern" },
				{ label: "Pinehurst", value: "Pinehurst" },
				{ label: "Raleigh", value: "Raleigh" },
				{ label: "Rocky Mount", value: "Rocky Mount" },
				{ label: "Salisbury", value: "Salisbury" },
				{ label: "Shelby", value: "Shelby" },
				{ label: "Washington", value: "Washington" },
				{ label: "Wilmington", value: "Wilmington" },
				{ label: "Wilson", value: "Wilson" },
				{ label: "Winston-Salem", value: "Winston-Salem" },
			],
		},
		{
			value: "ND",
			label: "North Dakota (ND)",
			json: [
				{ label: "Bismarck", value: "Bismarck" },
				{ label: "Devils Lake", value: "Devils Lake" },
				{ label: "Dickinson", value: "Dickinson" },
				{ label: "Fargo", value: "Fargo" },
				{ label: "Grand Forks", value: "Grand Forks" },
				{ label: "Jamestown", value: "Jamestown" },
				{ label: "Mandan", value: "Mandan" },
				{ label: "Minot", value: "Minot" },
				{ label: "Rugby", value: "Rugby" },
				{ label: "Valley City", value: "Valley City" },
				{ label: "Wahpeton", value: "Wahpeton" },
				{ label: "Williston", value: "Williston" },
			],
		},
		{
			value: "OH",
			label: "Ohio (OH)",
			json: [
				{ label: "Akron", value: "Akron" },
				{ label: "Alliance", value: "Alliance" },
				{ label: "Ashtabula", value: "Ashtabula" },
				{ label: "Athens", value: "Athens" },
				{ label: "Barberton", value: "Barberton" },
				{ label: "Bedford", value: "Bedford" },
				{ label: "Bellefontaine", value: "Bellefontaine" },
				{ label: "Bowling Green", value: "Bowling Green" },
				{ label: "Canton", value: "Canton" },
				{ label: "Chillicothe", value: "Chillicothe" },
				{ label: "Cincinnati", value: "Cincinnati" },
				{ label: "Cleveland", value: "Cleveland" },
				{ label: "Cleveland Heights", value: "Cleveland Heights" },
				{ label: "Columbus", value: "Columbus" },
				{ label: "Conneaut", value: "Conneaut" },
				{ label: "Cuyahoga Falls", value: "Cuyahoga Falls" },
				{ label: "Dayton", value: "Dayton" },
				{ label: "Defiance", value: "Defiance" },
				{ label: "Delaware", value: "Delaware" },
				{ label: "East Cleveland", value: "East Cleveland" },
				{ label: "East Liverpool", value: "East Liverpool" },
				{ label: "Elyria", value: "Elyria" },
				{ label: "Euclid", value: "Euclid" },
				{ label: "Findlay", value: "Findlay" },
				{ label: "Gallipolis", value: "Gallipolis" },
				{ label: "Greenville", value: "Greenville" },
				{ label: "Hamilton", value: "Hamilton" },
				{ label: "Kent", value: "Kent" },
				{ label: "Kettering", value: "Kettering" },
				{ label: "Lakewood", value: "Lakewood" },
				{ label: "Lancaster", value: "Lancaster" },
				{ label: "Lima", value: "Lima" },
				{ label: "Lorain", value: "Lorain" },
				{ label: "Mansfield", value: "Mansfield" },
				{ label: "Marietta", value: "Marietta" },
				{ label: "Marion", value: "Marion" },
				{ label: "Martins Ferry", value: "Martins Ferry" },
				{ label: "Massillon", value: "Massillon" },
				{ label: "Mentor", value: "Mentor" },
				{ label: "Middletown", value: "Middletown" },
				{ label: "Milan", value: "Milan" },
				{ label: "Mount Vernon", value: "Mount Vernon" },
				{ label: "New Philadelphia", value: "New Philadelphia" },
				{ label: "Newark", value: "Newark" },
				{ label: "Niles", value: "Niles" },
				{ label: "North College Hill", value: "North College Hill" },
				{ label: "Norwalk", value: "Norwalk" },
				{ label: "Oberlin", value: "Oberlin" },
				{ label: "Painesville", value: "Painesville" },
				{ label: "Parma", value: "Parma" },
				{ label: "Piqua", value: "Piqua" },
				{ label: "Portsmouth", value: "Portsmouth" },
				{ label: "Put-in-Bay", value: "Put-in-Bay" },
				{ label: "Salem", value: "Salem" },
				{ label: "Sandusky", value: "Sandusky" },
				{ label: "Shaker Heights", value: "Shaker Heights" },
				{ label: "Springfield", value: "Springfield" },
				{ label: "Steubenville", value: "Steubenville" },
				{ label: "Tiffin", value: "Tiffin" },
				{ label: "Toledo", value: "Toledo" },
				{ label: "Urbana", value: "Urbana" },
				{ label: "Warren", value: "Warren" },
				{ label: "Wooster", value: "Wooster" },
				{ label: "Worthington", value: "Worthington" },
				{ label: "Xenia", value: "Xenia" },
				{ label: "Yellow Springs", value: "Yellow Springs" },
				{ label: "Youngstown", value: "Youngstown" },
				{ label: "Zanesville", value: "Zanesville" },
			],
		},
		{
			value: "OK",
			label: "Oklahoma (OK)",
			json: [
				{ label: "Ada", value: "Ada" },
				{ label: "Altus", value: "Altus" },
				{ label: "Alva", value: "Alva" },
				{ label: "Anadarko", value: "Anadarko" },
				{ label: "Ardmore", value: "Ardmore" },
				{ label: "Bartlesville", value: "Bartlesville" },
				{ label: "Bethany", value: "Bethany" },
				{ label: "Chickasha", value: "Chickasha" },
				{ label: "Claremore", value: "Claremore" },
				{ label: "Clinton", value: "Clinton" },
				{ label: "Cushing", value: "Cushing" },
				{ label: "Duncan", value: "Duncan" },
				{ label: "Durant", value: "Durant" },
				{ label: "Edmond", value: "Edmond" },
				{ label: "El Reno", value: "El Reno" },
				{ label: "Elk City", value: "Elk City" },
				{ label: "Enid", value: "Enid" },
				{ label: "Eufaula", value: "Eufaula" },
				{ label: "Frederick", value: "Frederick" },
				{ label: "Guthrie", value: "Guthrie" },
				{ label: "Guymon", value: "Guymon" },
				{ label: "Hobart", value: "Hobart" },
				{ label: "Holdenville", value: "Holdenville" },
				{ label: "Hugo", value: "Hugo" },
				{ label: "Lawton", value: "Lawton" },
				{ label: "McAlester", value: "McAlester" },
				{ label: "Miami", value: "Miami" },
				{ label: "Midwest City", value: "Midwest City" },
				{ label: "Moore", value: "Moore" },
				{ label: "Muskogee", value: "Muskogee" },
				{ label: "Norman", value: "Norman" },
				{ label: "Oklahoma City", value: "Oklahoma City" },
				{ label: "Okmulgee", value: "Okmulgee" },
				{ label: "Pauls Valley", value: "Pauls Valley" },
				{ label: "Pawhuska", value: "Pawhuska" },
				{ label: "Perry", value: "Perry" },
				{ label: "Ponca City", value: "Ponca City" },
				{ label: "Pryor", value: "Pryor" },
				{ label: "Sallisaw", value: "Sallisaw" },
				{ label: "Sand Springs", value: "Sand Springs" },
				{ label: "Sapulpa", value: "Sapulpa" },
				{ label: "Seminole", value: "Seminole" },
				{ label: "Shawnee", value: "Shawnee" },
				{ label: "Stillwater", value: "Stillwater" },
				{ label: "Tahlequah", value: "Tahlequah" },
				{ label: "The Village", value: "The Village" },
				{ label: "Tulsa", value: "Tulsa" },
				{ label: "Vinita", value: "Vinita" },
				{ label: "Wewoka", value: "Wewoka" },
				{ label: "Woodward", value: "Woodward" },
			],
		},
		{
			value: "OR",
			label: "Oregon (OR)",
			json: [
				{ label: "Albany", value: "Albany" },
				{ label: "Ashland", value: "Ashland" },
				{ label: "Astoria", value: "Astoria" },
				{ label: "Baker City", value: "Baker City" },
				{ label: "Beaverton", value: "Beaverton" },
				{ label: "Bend", value: "Bend" },
				{ label: "Brookings", value: "Brookings" },
				{ label: "Burns", value: "Burns" },
				{ label: "Coos Bay", value: "Coos Bay" },
				{ label: "Corvallis", value: "Corvallis" },
				{ label: "Eugene", value: "Eugene" },
				{ label: "Grants Pass", value: "Grants Pass" },
				{ label: "Hillsboro", value: "Hillsboro" },
				{ label: "Hood River", value: "Hood River" },
				{ label: "Jacksonville", value: "Jacksonville" },
				{ label: "John Day", value: "John Day" },
				{ label: "Klamath Falls", value: "Klamath Falls" },
				{ label: "La Grande", value: "La Grande" },
				{ label: "Lake Oswego", value: "Lake Oswego" },
				{ label: "Lakeview", value: "Lakeview" },
				{ label: "McMinnville", value: "McMinnville" },
				{ label: "Medford", value: "Medford" },
				{ label: "Newberg", value: "Newberg" },
				{ label: "Newport", value: "Newport" },
				{ label: "Ontario", value: "Ontario" },
				{ label: "Oregon City", value: "Oregon City" },
				{ label: "Pendleton", value: "Pendleton" },
				{ label: "Port Orford", value: "Port Orford" },
				{ label: "Portland", value: "Portland" },
				{ label: "Prineville", value: "Prineville" },
				{ label: "Redmond", value: "Redmond" },
				{ label: "Reedsport", value: "Reedsport" },
				{ label: "Roseburg", value: "Roseburg" },
				{ label: "Salem", value: "Salem" },
				{ label: "Seaside", value: "Seaside" },
				{ label: "Springfield", value: "Springfield" },
				{ label: "The Dalles", value: "The Dalles" },
				{ label: "Tillamook", value: "Tillamook" },
			],
		},
		{
			value: "PA",
			label: "Pennsylvania (PA)",
			json: [
				{ label: "Abington", value: "Abington" },
				{ label: "Aliquippa", value: "Aliquippa" },
				{ label: "Allentown", value: "Allentown" },
				{ label: "Altoona", value: "Altoona" },
				{ label: "Ambridge", value: "Ambridge" },
				{ label: "Bedford", value: "Bedford" },
				{ label: "Bethlehem", value: "Bethlehem" },
				{ label: "Bloomsburg", value: "Bloomsburg" },
				{ label: "Bradford", value: "Bradford" },
				{ label: "Bristol", value: "Bristol" },
				{ label: "Carbondale", value: "Carbondale" },
				{ label: "Carlisle", value: "Carlisle" },
				{ label: "Chambersburg", value: "Chambersburg" },
				{ label: "Chester", value: "Chester" },
				{ label: "Columbia", value: "Columbia" },
				{ label: "Easton", value: "Easton" },
				{ label: "Erie", value: "Erie" },
				{ label: "Franklin", value: "Franklin" },
				{ label: "Germantown", value: "Germantown" },
				{ label: "Gettysburg", value: "Gettysburg" },
				{ label: "Greensburg", value: "Greensburg" },
				{ label: "Hanover", value: "Hanover" },
				{ label: "Harmony", value: "Harmony" },
				{ label: "Harrisburg", value: "Harrisburg" },
				{ label: "Hazleton", value: "Hazleton" },
				{ label: "Hershey", value: "Hershey" },
				{ label: "Homestead", value: "Homestead" },
				{ label: "Honesdale", value: "Honesdale" },
				{ label: "Indiana", value: "Indiana" },
				{ label: "Jeannette", value: "Jeannette" },
				{ label: "Jim Thorpe", value: "Jim Thorpe" },
				{ label: "Johnstown", value: "Johnstown" },
				{ label: "Lancaster", value: "Lancaster" },
				{ label: "Lebanon", value: "Lebanon" },
				{ label: "Levittown", value: "Levittown" },
				{ label: "Lewistown", value: "Lewistown" },
				{ label: "Lock Haven", value: "Lock Haven" },
				{ label: "Lower Southampton", value: "Lower Southampton" },
				{ label: "McKeesport", value: "McKeesport" },
				{ label: "Meadville", value: "Meadville" },
				{ label: "Middletown", value: "Middletown" },
				{ label: "Monroeville", value: "Monroeville" },
				{ label: "Nanticoke", value: "Nanticoke" },
				{ label: "New Castle", value: "New Castle" },
				{ label: "New Hope", value: "New Hope" },
				{ label: "New Kensington", value: "New Kensington" },
				{ label: "Norristown", value: "Norristown" },
				{ label: "Oil City", value: "Oil City" },
				{ label: "Philadelphia", value: "Philadelphia" },
				{ label: "Phoenixville", value: "Phoenixville" },
				{ label: "Pittsburgh", value: "Pittsburgh" },
				{ label: "Pottstown", value: "Pottstown" },
				{ label: "Pottsville", value: "Pottsville" },
				{ label: "Reading", value: "Reading" },
				{ label: "Scranton", value: "Scranton" },
				{ label: "Shamokin", value: "Shamokin" },
				{ label: "Sharon", value: "Sharon" },
				{ label: "State College", value: "State College" },
				{ label: "Stroudsburg", value: "Stroudsburg" },
				{ label: "Sunbury", value: "Sunbury" },
				{ label: "Swarthmore", value: "Swarthmore" },
				{ label: "Tamaqua", value: "Tamaqua" },
				{ label: "Titusville", value: "Titusville" },
				{ label: "Uniontown", value: "Uniontown" },
				{ label: "Warren", value: "Warren" },
				{ label: "Washington", value: "Washington" },
				{ label: "West Chester", value: "West Chester" },
				{ label: "Wilkes-Barre", value: "Wilkes-Barre" },
				{ label: "Williamsport", value: "Williamsport" },
				{ label: "York", value: "York" },
			],
		},
		{
			value: "PR",
			label: "Puerto Rico (PR)",
			json: [
				{ label: "Adjuntas", value: "Adjuntas" },
				{ label: "Aguada", value: "Aguada" },
				{ label: "Aguadilla", value: "Aguadilla" },
				{ label: "Aguas Buenas", value: "Aguas Buenas" },
				{ label: "Aibonito", value: "Aibonito" },
				{ label: "Añasco", value: "Añasco" },
				{ label: "Arecibo", value: "Arecibo" },
				{ label: "Arroyo", value: "Arroyo" },
				{ label: "Barceloneta", value: "Barceloneta" },
				{ label: "Barranquitas", value: "Barranquitas" },
				{ label: "Bayamón", value: "Bayamón" },
				{ label: "Cabo Rojo", value: "Cabo Rojo" },
				{ label: "Caguas", value: "Caguas" },
				{ label: "Camuy", value: "Camuy" },
				{ label: "Canóvanas", value: "Canóvanas" },
				{ label: "Carolina", value: "Carolina" },
				{ label: "Cataño", value: "Cataño" },
				{ label: "Cayey", value: "Cayey" },
				{ label: "Ceiba", value: "Ceiba" },
				{ label: "Ciales", value: "Ciales" },
				{ label: "Cidra", value: "Cidra" },
				{ label: "Coamo", value: "Coamo" },
				{ label: "Comerio", value: "Comerio" },
				{ label: "Corozal", value: "Corozal" },
				{ label: "Culebra", value: "Culebra" },
				{ label: "Dorado", value: "Dorado" },
				{ label: "Fajardo", value: "Fajardo" },
				{ label: "Florida", value: "Florida" },
				{ label: "Guánica", value: "Guánica" },
				{ label: "Guayama", value: "Guayama" },
				{ label: "Guayanilla", value: "Guayanilla" },
				{ label: "Guaynabo", value: "Guaynabo" },
				{ label: "Gurabo", value: "Gurabo" },
				{ label: "Hatillo", value: "Hatillo" },
				{ label: "Hormigueros", value: "Hormigueros" },
				{ label: "Humacao", value: "Humacao" },
				{ label: "Isabela", value: "Isabela" },
				{ label: "Jayuya", value: "Jayuya" },
				{ label: "Juana Díaz", value: "Juana Díaz" },
				{ label: "Juncos", value: "Juncos" },
				{ label: "Lajas", value: "Lajas" },
				{ label: "Lares", value: "Lares" },
				{ label: "Las Marías", value: "Las Marías" },
				{ label: "Las Piedras", value: "Las Piedras" },
				{ label: "Loíza", value: "Loíza" },
				{ label: "Luquillo", value: "Luquillo" },
				{ label: "Manatí", value: "Manatí" },
				{ label: "Maricao", value: "Maricao" },
				{ label: "Maunabo", value: "Maunabo" },
				{ label: "Mayagüez", value: "Mayagüez" },
				{ label: "Moca", value: "Moca" },
				{ label: "Morovis", value: "Morovis" },
				{ label: "Naguabo", value: "Naguabo" },
				{ label: "Naranjito", value: "Naranjito" },
				{ label: "Orocovis", value: "Orocovis" },
				{ label: "Patillas", value: "Patillas" },
				{ label: "Peñuelas", value: "Peñuelas" },
				{ label: "Ponce", value: "Ponce" },
				{ label: "Quebradillas", value: "Quebradillas" },
				{ label: "Rincón", value: "Rincón" },
				{ label: "Río Grande", value: "Río Grande" },
				{ label: "Sabana Grande", value: "Sabana Grande" },
				{ label: "Salinas", value: "Salinas" },
				{ label: "San Germán", value: "San Germán" },
				{ label: "San Juan", value: "San Juan" },
				{ label: "San Lorenzo", value: "San Lorenzo" },
				{ label: "San Sebastián", value: "San Sebastián" },
				{ label: "Santa Isabel", value: "Santa Isabel" },
				{ label: "Toa Alta", value: "Toa Alta" },
				{ label: "Toa Baja", value: "Toa Baja" },
				{ label: "Trujillo Alto", value: "Trujillo Alto" },
				{ label: "Utuado", value: "Utuado" },
				{ label: "Vega Alta", value: "Vega Alta" },
				{ label: "Vega Baja", value: "Vega Baja" },
				{ label: "Vieques", value: "Vieques" },
				{ label: "Villalba", value: "Villalba" },
				{ label: "Yabucoa", value: "Yabucoa" },
				{ label: "Yauco", value: "Yauco" },
			],
		},
		{
			value: "RI",
			label: "Rhode Island (RI)",
			json: [
				{ label: "Barrington", value: "Barrington" },
				{ label: "Bristol", value: "Bristol" },
				{ label: "Central Falls", value: "Central Falls" },
				{ label: "Cranston", value: "Cranston" },
				{ label: "East Greenwich", value: "East Greenwich" },
				{ label: "East Providence", value: "East Providence" },
				{ label: "Kingston", value: "Kingston" },
				{ label: "Middletown", value: "Middletown" },
				{ label: "Narragansett", value: "Narragansett" },
				{ label: "Newport", value: "Newport" },
				{ label: "North Kingstown", value: "North Kingstown" },
				{ label: "Pawtucket", value: "Pawtucket" },
				{ label: "Portsmouth", value: "Portsmouth" },
				{ label: "Providence", value: "Providence" },
				{ label: "South Kingstown", value: "South Kingstown" },
				{ label: "Tiverton", value: "Tiverton" },
				{ label: "Warren", value: "Warren" },
				{ label: "Warwick", value: "Warwick" },
				{ label: "Westerly", value: "Westerly" },
				{ label: "Wickford", value: "Wickford" },
				{ label: "Woonsocket", value: "Woonsocket" },
			],
		},
		{
			value: "SC",
			label: "South Carolina (SC)",
			json: [
				{ label: "Abbeville", value: "Abbeville" },
				{ label: "Aiken", value: "Aiken" },
				{ label: "Anderson", value: "Anderson" },
				{ label: "Beaufort", value: "Beaufort" },
				{ label: "Camden", value: "Camden" },
				{ label: "Charleston", value: "Charleston" },
				{ label: "Columbia", value: "Columbia" },
				{ label: "Darlington", value: "Darlington" },
				{ label: "Florence", value: "Florence" },
				{ label: "Gaffney", value: "Gaffney" },
				{ label: "Georgetown", value: "Georgetown" },
				{ label: "Greenville", value: "Greenville" },
				{ label: "Greenwood", value: "Greenwood" },
				{ label: "Hartsville", value: "Hartsville" },
				{ label: "Lancaster", value: "Lancaster" },
				{ label: "Mount Pleasant", value: "Mount Pleasant" },
				{ label: "Myrtle Beach", value: "Myrtle Beach" },
				{ label: "Orangeburg", value: "Orangeburg" },
				{ label: "Rock Hill", value: "Rock Hill" },
				{ label: "Spartanburg", value: "Spartanburg" },
				{ label: "Sumter", value: "Sumter" },
				{ label: "Union", value: "Union" },
			],
		},
		{
			value: "SD",
			label: "South Dakota (SD)",
			json: [
				{ label: "Aberdeen", value: "Aberdeen" },
				{ label: "Belle Fourche", value: "Belle Fourche" },
				{ label: "Brookings", value: "Brookings" },
				{ label: "Canton", value: "Canton" },
				{ label: "Custer", value: "Custer" },
				{ label: "De Smet", value: "De Smet" },
				{ label: "Deadwood", value: "Deadwood" },
				{ label: "Hot Springs", value: "Hot Springs" },
				{ label: "Huron", value: "Huron" },
				{ label: "Lead", value: "Lead" },
				{ label: "Madison", value: "Madison" },
				{ label: "Milbank", value: "Milbank" },
				{ label: "Mitchell", value: "Mitchell" },
				{ label: "Mobridge", value: "Mobridge" },
				{ label: "Pierre", value: "Pierre" },
				{ label: "Rapid City", value: "Rapid City" },
				{ label: "Sioux Falls", value: "Sioux Falls" },
				{ label: "Spearfish", value: "Spearfish" },
				{ label: "Sturgis", value: "Sturgis" },
				{ label: "Vermillion", value: "Vermillion" },
				{ label: "Watertown", value: "Watertown" },
				{ label: "Yankton", value: "Yankton" },
			],
		},
		{
			value: "TN",
			label: "Tennessee (TN)",
			json: [
				{ label: "Alcoa", value: "Alcoa" },
				{ label: "Athens", value: "Athens" },
				{ label: "Chattanooga", value: "Chattanooga" },
				{ label: "Clarksville", value: "Clarksville" },
				{ label: "Cleveland", value: "Cleveland" },
				{ label: "Columbia", value: "Columbia" },
				{ label: "Cookeville", value: "Cookeville" },
				{ label: "Dayton", value: "Dayton" },
				{ label: "Elizabethton", value: "Elizabethton" },
				{ label: "Franklin", value: "Franklin" },
				{ label: "Gallatin", value: "Gallatin" },
				{ label: "Gatlinburg", value: "Gatlinburg" },
				{ label: "Greeneville", value: "Greeneville" },
				{ label: "Jackson", value: "Jackson" },
				{ label: "Johnson City", value: "Johnson City" },
				{ label: "Jonesborough", value: "Jonesborough" },
				{ label: "Kingsport", value: "Kingsport" },
				{ label: "Knoxville", value: "Knoxville" },
				{ label: "Lebanon", value: "Lebanon" },
				{ label: "Maryville", value: "Maryville" },
				{ label: "Memphis", value: "Memphis" },
				{ label: "Morristown", value: "Morristown" },
				{ label: "Murfreesboro", value: "Murfreesboro" },
				{ label: "Nashville", value: "Nashville" },
				{ label: "Norris", value: "Norris" },
				{ label: "Oak Ridge", value: "Oak Ridge" },
				{ label: "Shelbyville", value: "Shelbyville" },
				{ label: "Tullahoma", value: "Tullahoma" },
			],
		},
		{
			value: "TX",
			label: "Texas (TX)",
			json: [
				{ label: "Abilene", value: "Abilene" },
				{ label: "Alpine", value: "Alpine" },
				{ label: "Amarillo", value: "Amarillo" },
				{ label: "Arlington", value: "Arlington" },
				{ label: "Austin", value: "Austin" },
				{ label: "Baytown", value: "Baytown" },
				{ label: "Beaumont", value: "Beaumont" },
				{ label: "Big Spring", value: "Big Spring" },
				{ label: "Borger", value: "Borger" },
				{ label: "Brownsville", value: "Brownsville" },
				{ label: "Bryan", value: "Bryan" },
				{ label: "Canyon", value: "Canyon" },
				{ label: "Cleburne", value: "Cleburne" },
				{ label: "College Station", value: "College Station" },
				{ label: "Corpus Christi", value: "Corpus Christi" },
				{ label: "Crystal City", value: "Crystal City" },
				{ label: "Dallas", value: "Dallas" },
				{ label: "Del Rio", value: "Del Rio" },
				{ label: "Denison", value: "Denison" },
				{ label: "Denton", value: "Denton" },
				{ label: "Eagle Pass", value: "Eagle Pass" },
				{ label: "Edinburg", value: "Edinburg" },
				{ label: "El Paso", value: "El Paso" },
				{ label: "Fort Worth", value: "Fort Worth" },
				{ label: "Freeport", value: "Freeport" },
				{ label: "Galveston", value: "Galveston" },
				{ label: "Garland", value: "Garland" },
				{ label: "Goliad", value: "Goliad" },
				{ label: "Greenville", value: "Greenville" },
				{ label: "Harlingen", value: "Harlingen" },
				{ label: "Houston", value: "Houston" },
				{ label: "Huntsville", value: "Huntsville" },
				{ label: "Irving", value: "Irving" },
				{ label: "Johnson City", value: "Johnson City" },
				{ label: "Kilgore", value: "Kilgore" },
				{ label: "Killeen", value: "Killeen" },
				{ label: "Kingsville", value: "Kingsville" },
				{ label: "Laredo", value: "Laredo" },
				{ label: "Longview", value: "Longview" },
				{ label: "Lubbock", value: "Lubbock" },
				{ label: "Lufkin", value: "Lufkin" },
				{ label: "Marshall", value: "Marshall" },
				{ label: "McAllen", value: "McAllen" },
				{ label: "McKinney", value: "McKinney" },
				{ label: "Mesquite", value: "Mesquite" },
				{ label: "Midland", value: "Midland" },
				{ label: "Mission", value: "Mission" },
				{ label: "Nacogdoches", value: "Nacogdoches" },
				{ label: "New Braunfels", value: "New Braunfels" },
				{ label: "Odessa", value: "Odessa" },
				{ label: "Orange", value: "Orange" },
				{ label: "Pampa", value: "Pampa" },
				{ label: "Paris", value: "Paris" },
				{ label: "Pasadena", value: "Pasadena" },
				{ label: "Pecos", value: "Pecos" },
				{ label: "Pharr", value: "Pharr" },
				{ label: "Plainview", value: "Plainview" },
				{ label: "Plano", value: "Plano" },
				{ label: "Port Arthur", value: "Port Arthur" },
				{ label: "Port Lavaca", value: "Port Lavaca" },
				{ label: "Richardson", value: "Richardson" },
				{ label: "San Angelo", value: "San Angelo" },
				{ label: "San Antonio", value: "San Antonio" },
				{ label: "San Felipe", value: "San Felipe" },
				{ label: "San Marcos", value: "San Marcos" },
				{ label: "Sherman", value: "Sherman" },
				{ label: "Sweetwater", value: "Sweetwater" },
				{ label: "Temple", value: "Temple" },
				{ label: "Texarkana", value: "Texarkana" },
				{ label: "Texas City", value: "Texas City" },
				{ label: "Tyler", value: "Tyler" },
				{ label: "Uvalde", value: "Uvalde" },
				{ label: "Victoria", value: "Victoria" },
				{ label: "Waco", value: "Waco" },
				{ label: "Weatherford", value: "Weatherford" },
				{ label: "Wichita Falls", value: "Wichita Falls" },
				{ label: "Ysleta", value: "Ysleta" },
			],
		},
		{
			value: "UT",
			label: "Utah (UT)",
			json: [
				{ label: "Alta", value: "Alta" },
				{ label: "American Fork", value: "American Fork" },
				{ label: "Bountiful", value: "Bountiful" },
				{ label: "Brigham City", value: "Brigham City" },
				{ label: "Cedar City", value: "Cedar City" },
				{ label: "Clearfield", value: "Clearfield" },
				{ label: "Delta", value: "Delta" },
				{ label: "Fillmore", value: "Fillmore" },
				{ label: "Green River", value: "Green River" },
				{ label: "Heber City", value: "Heber City" },
				{ label: "Kanab", value: "Kanab" },
				{ label: "Layton", value: "Layton" },
				{ label: "Lehi", value: "Lehi" },
				{ label: "Logan", value: "Logan" },
				{ label: "Manti", value: "Manti" },
				{ label: "Moab", value: "Moab" },
				{ label: "Monticello", value: "Monticello" },
				{ label: "Murray", value: "Murray" },
				{ label: "Nephi", value: "Nephi" },
				{ label: "Ogden", value: "Ogden" },
				{ label: "Orderville", value: "Orderville" },
				{ label: "Orem", value: "Orem" },
				{ label: "Panguitch", value: "Panguitch" },
				{ label: "Park City", value: "Park City" },
				{ label: "Payson", value: "Payson" },
				{ label: "Price", value: "Price" },
				{ label: "Provo", value: "Provo" },
				{ label: "Saint George", value: "Saint George" },
				{ label: "Salt Lake City", value: "Salt Lake City" },
				{ label: "Spanish Fork", value: "Spanish Fork" },
				{ label: "Springville", value: "Springville" },
				{ label: "Tooele", value: "Tooele" },
				{ label: "Vernal", value: "Vernal" },
			],
		},
		{
			value: "VT",
			label: "Vermont (VT)",
			json: [
				{ label: "Barre", value: "Barre" },
				{ label: "Bellows Falls", value: "Bellows Falls" },
				{ label: "Bennington", value: "Bennington" },
				{ label: "Brattleboro", value: "Brattleboro" },
				{ label: "Burlington", value: "Burlington" },
				{ label: "Essex", value: "Essex" },
				{ label: "Manchester", value: "Manchester" },
				{ label: "Middlebury", value: "Middlebury" },
				{ label: "Montpelier", value: "Montpelier" },
				{ label: "Newport", value: "Newport" },
				{ label: "Plymouth", value: "Plymouth" },
				{ label: "Rutland", value: "Rutland" },
				{ label: "Saint Albans", value: "Saint Albans" },
				{ label: "Saint Johnsbury", value: "Saint Johnsbury" },
				{ label: "Sharon", value: "Sharon" },
				{ label: "Winooski", value: "Winooski" },
			],
		},
		{
			value: "VI",
			label: "Virgin Islands (VI)",
			json: [
				{ label: "Charlotte Amalie", value: "Charlotte Amalie" },
				{ label: "Christiansted", value: "Christiansted" },
				{ label: "Cruz Bay", value: "Cruz Bay" },
				{ label: "Frederiksted", value: "Frederiksted" },
				{ label: "Red Hook, Saint Thomas", value: "Red Hook, Saint Thomas" },
			],
		},
		{
			value: "VA",
			label: "Virginia (VA)",
			json: [
				{ label: "Abingdon", value: "Abingdon" },
				{ label: "Alexandria", value: "Alexandria" },
				{ label: "Bristol", value: "Bristol" },
				{ label: "Charlottesville", value: "Charlottesville" },
				{ label: "Chesapeake", value: "Chesapeake" },
				{ label: "Danville", value: "Danville" },
				{ label: "Fairfax", value: "Fairfax" },
				{ label: "Falls Church", value: "Falls Church" },
				{ label: "Fredericksburg", value: "Fredericksburg" },
				{ label: "Hampton", value: "Hampton" },
				{ label: "Hanover", value: "Hanover" },
				{ label: "Hopewell", value: "Hopewell" },
				{ label: "Lexington", value: "Lexington" },
				{ label: "Lynchburg", value: "Lynchburg" },
				{ label: "Manassas", value: "Manassas" },
				{ label: "Martinsville", value: "Martinsville" },
				{ label: "New Market", value: "New Market" },
				{ label: "Newport News", value: "Newport News" },
				{ label: "Norfolk", value: "Norfolk" },
				{ label: "Petersburg", value: "Petersburg" },
				{ label: "Portsmouth", value: "Portsmouth" },
				{ label: "Reston", value: "Reston" },
				{ label: "Richmond", value: "Richmond" },
				{ label: "Roanoke", value: "Roanoke" },
				{ label: "Staunton", value: "Staunton" },
				{ label: "Suffolk", value: "Suffolk" },
				{ label: "Virginia Beach", value: "Virginia Beach" },
				{ label: "Waynesboro", value: "Waynesboro" },
				{ label: "Williamsburg", value: "Williamsburg" },
				{ label: "Winchester", value: "Winchester" },
			],
		},
		{
			value: "WA",
			label: "Washington (WA)",
			json: [
				{ label: "Aberdeen", value: "Aberdeen" },
				{ label: "Anacortes", value: "Anacortes" },
				{ label: "Auburn", value: "Auburn" },
				{ label: "Bellevue", value: "Bellevue" },
				{ label: "Bellingham", value: "Bellingham" },
				{ label: "Bremerton", value: "Bremerton" },
				{ label: "Centralia", value: "Centralia" },
				{ label: "Coulee Dam", value: "Coulee Dam" },
				{ label: "Coupeville", value: "Coupeville" },
				{ label: "Ellensburg", value: "Ellensburg" },
				{ label: "Ephrata", value: "Ephrata" },
				{ label: "Everett", value: "Everett" },
				{ label: "Hoquiam", value: "Hoquiam" },
				{ label: "Kelso", value: "Kelso" },
				{ label: "Kennewick", value: "Kennewick" },
				{ label: "Longview", value: "Longview" },
				{ label: "Moses Lake", value: "Moses Lake" },
				{ label: "Oak Harbor", value: "Oak Harbor" },
				{ label: "Olympia", value: "Olympia" },
				{ label: "Pasco", value: "Pasco" },
				{ label: "Point Roberts", value: "Point Roberts" },
				{ label: "Port Angeles", value: "Port Angeles" },
				{ label: "Pullman", value: "Pullman" },
				{ label: "Puyallup", value: "Puyallup" },
				{ label: "Redmond", value: "Redmond" },
				{ label: "Renton", value: "Renton" },
				{ label: "Richland", value: "Richland" },
				{ label: "Seattle", value: "Seattle" },
				{ label: "Spokane", value: "Spokane" },
				{ label: "Tacoma", value: "Tacoma" },
				{ label: "Vancouver", value: "Vancouver" },
				{ label: "Walla Walla", value: "Walla Walla" },
				{ label: "Wenatchee", value: "Wenatchee" },
				{ label: "Yakima", value: "Yakima" },
			],
		},
		{
			value: "WV",
			label: "West Virginia (WV)",
			json: [
				{ label: "Bath", value: "Bath" },
				{ label: "Beckley", value: "Beckley" },
				{ label: "Bluefield", value: "Bluefield" },
				{ label: "Buckhannon", value: "Buckhannon" },
				{ label: "Charles Town", value: "Charles Town" },
				{ label: "Charleston", value: "Charleston" },
				{ label: "Clarksburg", value: "Clarksburg" },
				{ label: "Elkins", value: "Elkins" },
				{ label: "Fairmont", value: "Fairmont" },
				{ label: "Grafton", value: "Grafton" },
				{ label: "Harpers Ferry", value: "Harpers Ferry" },
				{ label: "Hillsboro", value: "Hillsboro" },
				{ label: "Hinton", value: "Hinton" },
				{ label: "Huntington", value: "Huntington" },
				{ label: "Keyser", value: "Keyser" },
				{ label: "Lewisburg", value: "Lewisburg" },
				{ label: "Logan", value: "Logan" },
				{ label: "Martinsburg", value: "Martinsburg" },
				{ label: "Morgantown", value: "Morgantown" },
				{ label: "Moundsville", value: "Moundsville" },
				{ label: "New Martinsville", value: "New Martinsville" },
				{ label: "Parkersburg", value: "Parkersburg" },
				{ label: "Philippi", value: "Philippi" },
				{ label: "Point Pleasant", value: "Point Pleasant" },
				{ label: "Princeton", value: "Princeton" },
				{ label: "Romney", value: "Romney" },
				{ label: "Shepherdstown", value: "Shepherdstown" },
				{ label: "South Charleston", value: "South Charleston" },
				{ label: "Summersville", value: "Summersville" },
				{ label: "Weirton", value: "Weirton" },
				{ label: "Welch", value: "Welch" },
				{ label: "Wellsburg", value: "Wellsburg" },
				{ label: "Weston", value: "Weston" },
				{ label: "Wheeling", value: "Wheeling" },
				{ label: "White Sulphur Springs", value: "White Sulphur Springs" },
				{ label: "Williamson", value: "Williamson" },
			],
		},
		{
			value: "WI",
			label: "Wisconsin (WI)",
			json: [
				{ label: "Appleton", value: "Appleton" },
				{ label: "Ashland", value: "Ashland" },
				{ label: "Baraboo", value: "Baraboo" },
				{ label: "Belmont", value: "Belmont" },
				{ label: "Beloit", value: "Beloit" },
				{ label: "Eau Claire", value: "Eau Claire" },
				{ label: "Fond du Lac", value: "Fond du Lac" },
				{ label: "Green Bay", value: "Green Bay" },
				{ label: "Hayward", value: "Hayward" },
				{ label: "Janesville", value: "Janesville" },
				{ label: "Kenosha", value: "Kenosha" },
				{ label: "La Crosse", value: "La Crosse" },
				{ label: "Lake Geneva", value: "Lake Geneva" },
				{ label: "Madison", value: "Madison" },
				{ label: "Manitowoc", value: "Manitowoc" },
				{ label: "Marinette", value: "Marinette" },
				{ label: "Menasha", value: "Menasha" },
				{ label: "Milwaukee", value: "Milwaukee" },
				{ label: "Neenah", value: "Neenah" },
				{ label: "New Glarus", value: "New Glarus" },
				{ label: "Oconto", value: "Oconto" },
				{ label: "Oshkosh", value: "Oshkosh" },
				{ label: "Peshtigo", value: "Peshtigo" },
				{ label: "Portage", value: "Portage" },
				{ label: "Prairie du Chien", value: "Prairie du Chien" },
				{ label: "Racine", value: "Racine" },
				{ label: "Rhinelander", value: "Rhinelander" },
				{ label: "Ripon", value: "Ripon" },
				{ label: "Sheboygan", value: "Sheboygan" },
				{ label: "Spring Green", value: "Spring Green" },
				{ label: "Stevens Point", value: "Stevens Point" },
				{ label: "Sturgeon Bay", value: "Sturgeon Bay" },
				{ label: "Superior", value: "Superior" },
				{ label: "Waukesha", value: "Waukesha" },
				{ label: "Wausau", value: "Wausau" },
				{ label: "Wauwatosa", value: "Wauwatosa" },
				{ label: "West Allis", value: "West Allis" },
				{ label: "West Bend", value: "West Bend" },
				{ label: "Wisconsin Dells", value: "Wisconsin Dells" },
			],
		},
		{
			value: "WY",
			label: "Wyoming (WY)",
			json: [
				{ label: "Buffalo", value: "Buffalo" },
				{ label: "Casper", value: "Casper" },
				{ label: "Cheyenne", value: "Cheyenne" },
				{ label: "Cody", value: "Cody" },
				{ label: "Douglas", value: "Douglas" },
				{ label: "Evanston", value: "Evanston" },
				{ label: "Gillette", value: "Gillette" },
				{ label: "Green River", value: "Green River" },
				{ label: "Jackson", value: "Jackson" },
				{ label: "Lander", value: "Lander" },
				{ label: "Laramie", value: "Laramie" },
				{ label: "Newcastle", value: "Newcastle" },
				{ label: "Powell", value: "Powell" },
				{ label: "Rawlins", value: "Rawlins" },
				{ label: "Riverton", value: "Riverton" },
				{ label: "Rock Springs", value: "Rock Springs" },
				{ label: "Sheridan", value: "Sheridan" },
				{ label: "Ten Sleep", value: "Ten Sleep" },
				{ label: "Thermopolis", value: "Thermopolis" },
				{ label: "Torrington", value: "Torrington" },
				{ label: "Worland", value: "Worland" },
			],
		},
	];
	// console.log("state-1", arr1);
	// console.log("state-2", arr);

	return arr;
}
